const traducaoStatusFaturaIugu = (text: string): string => {
  const codigo = text.split('LR: ');

  switch (codigo[1]) {
    case '0':
      return 'Transação autorizada com sucesso';
    case '00':
      return 'Transação autorizada com sucesso';
    case '1':
      return ' Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.';
    case '2':
      return 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.';
    case '3':
      return 'Não foi possível processar a transação.';
    case '4':
      return 'Transação não autorizada. Cartão bloqueado pelo banco emissor.';
    case '5':
      return 'Transação não autorizada. Cartão inadimplente (Do not honor).';
    case '6':
      return 'Transação não autorizada. Cartão cancelado.';
    case '7':
      return 'Transação negada.';
    case '8':
      return 'Transação não autorizada. Código de segurança inválido.';
    case '9':
      return 'Transação cancelada parcialmente com sucesso.';
    case '11':
      return 'Transação autorizada com sucesso para cartão emitido no exterior';
    case '12':
      return 'Transação inválida, erro no cartão.';
    case '13':
      return 'Transação não permitida. Valor da transação Inválido.';
    case '14':
      return 'Transação não autorizada. Cartão Inválido';
    case '15':
      return 'Banco emissor indisponível ou inexistente.';
    case '21':
      return 'Cancelamento não efetuado. Transação não localizada.';
    case '22':
      return 'Parcelamento inválido. Número de parcelas inválidas.';
    case '23':
      return 'Transação não autorizada. Valor da prestação inválido.';
    case '24':
      return 'Transação não autorizada. Valor da prestação inválido.';
    case '25':
      return 'Pedido de autorização não enviou número do cartão';
    case '28':
      return 'Arquivo temporariamente indisponível.';
    case '30':
      return 'Transação não autorizada. Decline Message';
    case '39':
      return 'Transação não autorizada. Erro no banco emissor.';
    case '41':
      return 'Transação não autorizada. Erro no banco emissor.';
    case '43':
      return 'Transação não autorizada. Cartão bloqueado por roubo.';
    case '51':
      return 'Transação não autorizada. Limite excedido/sem saldo.';
    case '52':
      return 'Transação não autorizada. Limite excedido/sem saldo.';
    case '53':
      return 'Transação não autorizada. Limite excedido/sem saldo.';
    case '54':
      return 'Transação não autorizada. Cartão vencido';
    case '55':
      return 'Transação não autorizada. Senha inválida';
    case '56':
      return 'NÚMERO CARTÃO NÃO PERTENCE AO EMISSOR | NÚMERO CARTÃO INVÁLIDO';
    case '57':
      return 'Transação não permitida para o cartão';
    case '58':
      return 'Transação não permitida. Opção de pagamento inválida';
    case '59':
      return 'Transação não autorizada. Suspeita de fraude.';
    case '60':
      return 'Transação não autorizada.';
    case '61':
      return 'Banco emissor indisponível.';
    case '62':
      return 'Transação não autorizada. Cartão restrito para uso doméstico';
    case '63':
      return 'Transação não autorizada. Violação de segurança';
    case '64':
      return 'Transação não autorizada. Valor abaixo do mínimo exigido pelo banco emissor.';
    case '65':
      return 'Transação não autorizada. Excedida a quantidade de transações para o cartão.';
    case '67':
      return 'Transação não autorizada. Cartão bloqueado para compras hoje.';
    case '70':
      return 'Transação não autorizada. Limite excedido/sem saldo.';
    case '72':
      return 'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente.';
    case '74':
      return 'Transação não autorizada. A senha está vencida.';
    case '75':
      return 'Senha bloqueada. Excedeu tentativas de cartão.';
    case '76':
      return 'Cancelamento não efetuado. Banco emissor não localizou a transação original';
    case '77':
      return 'Cancelamento não efetuado. Não foi localizado a transação original';
    case '78':
      return 'Transação não autorizada. Cartão bloqueado primeiro uso.';
    case '79':
      return 'Transação não autorizada.';
    case '80':
      return 'Transação não autorizada. Divergencia na data de transação/pagamento.';
    case '81':
      return 'Transação não autorizada. A senha está vencida.';
    case '82':
      return 'Transação não autorizada. Cartão inválido.';
    case '83':
      return 'Transação não autorizada. Erro no controle de senhas';
    case '85':
      return 'Transação não permitida. Falha da operação.';
    case '86':
      return 'Transação não permitida. Falha da operação.';
    case '88':
      return 'Falha na criptografia dos dados.';
    case '89':
      return 'Erro na transação.';
    case '90':
      return 'Transação não permitida. Falha da operação.';
    case '91':
      return 'Transação não autorizada. Banco emissor temporariamente indisponível.';
    case '92':
      return 'Transação não autorizada. Tempo de comunicação excedido.';
    case '93':
      return 'Transação não autorizada. Violação de regra, possível erro no cadastro.';
    case '94':
      return 'Transação duplicada.';
    case '96':
      return 'Falha no processamento.';
    case '97':
      return 'Valor não permitido para essa transação.';
    case '98':
      return 'Sistema/comunicação indisponível.';
    case '99':
      return 'Sistema/comunicação indisponível.';
    case '475':
      return 'Timeout de Cancelamento';
    case '999':
      return 'Sistema/comunicação indisponível.';
    case 'A2':
      return 'VERIFIQUE OS DADOS DO CARTÃO';
    case 'A3':
      return 'ERRO NO CARTÃO';
    case 'A5':
      return 'TRANSAÇÃO NÃO PERMITIDA';
    case 'A7':
      return 'ERRO NO CARTÃO ';
    case 'AA':
      return 'Tempo Excedido';
    case 'AB':
      return 'FUNÇÃO INCORRETA (DÉBITO)';
    case 'AC':
      return 'Transação não permitida. Cartão de débito sendo usado com crédito. ';
    case 'AE':
      return 'Tente Mais Tarde';
    case 'AF':
      return 'Transação não permitida. Falha da operação.';
    case 'AG':
      return 'Transação não permitida. Falha da operação.';
    case 'AH':
      return 'Transação não permitida. Cartão de crédito sendo usado com débito. ';
    case 'AI':
      return 'Transação não autorizada. Autenticação não foi realizada.';
    case 'AJ':
      return 'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. ';
    case 'AV':
      return 'Transação não autorizada. Dados Inválidos';
    case 'BD':
      return 'Transação não permitida. Falha da operação.';
    case 'BL':
      return 'Transação não autorizada. Limite diário excedido.';
    case 'BM':
      return 'Transação não autorizada. Cartão Inválido';
    case 'BN':
      return 'Transação não autorizada. Cartão ou conta bloqueado.';
    case 'BO':
      return 'Transação não permitida. Falha da operação.';
    case 'BP':
      return 'Transação não autorizada. Conta corrente inexistente.';
    case 'BP176':
      return 'Transação não permitida.';
    case 'BV':
      return 'Transação não autorizada. Cartão vencido';
    case 'CF':
      return 'Transação não autorizada.C79:J79 Falha na validação dos dados.';
    case 'CG':
      return 'Transação não autorizada. Falha na validação dos dados.';
    case 'DA':
      return 'Transação não autorizada. Falha na validação dos dados.';
    case 'DF':
      return 'Transação não permitida. Falha no cartão ou cartão inválido.';
    case 'DM':
      return 'Transação não autorizada. Limite excedido/sem saldo.';
    case 'DQ':
      return 'Transação não autorizada. Falha na validação dos dados.';
    case 'DS':
      return 'Transação não permitida para o cartão';
    case 'EB':
      return 'Transação não autorizada. Limite diário excedido.';
    default:
      return text;
  }
};

export default traducaoStatusFaturaIugu;
