/* eslint-disable import/prefer-default-export */
export const SIGNATURE = '@[SPEEDPIX=PIX]';

export const STATE_REDUX = '@[REDUX-SPEEDPIX]';

export const TOKEN_INTEGRACAO_WHATSAPP = '16b52245fe8b00ede75bd126';

export const PAGAMENTO_MANUAL = 1;

export const ETAPA_AGUARDANDO = 1;

export const ETAPA_PAGA = 3;

export const BANCOS_COM_VERIFICACAO = ['001', '237', '341', '999'];

export const TOKEN_IUGU_DEVEL = '4C5BDDA0A937D72A9ACCBB64331D60D8A16E7699D95E2FD8EAC45DF904131BAC';

export const TOKEN_IUGU_PROD = 'E7999F9566C04BE8C81562899D9620F4FCA904DB46F43437CB704E394FE8B3CB';
