/* eslint-disable no-unused-vars */
import {
  useState, createContext, ReactNode, useEffect, useCallback, ReactElement,
} from 'react';
import { Assinatura } from '../@types';
import useUser from '../hooks/useUser';
import { api, exibirErrorCatch, loading } from '../utils';

interface Props {
  children: ReactNode;
}

export interface StateAssinaturaAtiva {
  assinatura: Assinatura | null,
  setAssinatura: (x: Assinatura | null) => void,
}

export const AssinaturaContext = createContext({} as StateAssinaturaAtiva);

const AssinaturaAtivaContext = ({ children }: Props): ReactElement => {
  const { user } = useUser();
  const [assinatura, setAssinatura] = useState<Assinatura | null>({} as Assinatura);

  const getAssinaturaAtiva = useCallback(async () => {
    try {
      if (!user) return;

      const { data } = await api.getAssinaturaAtiva();

      setAssinatura(data);
    } catch (error) {
      exibirErrorCatch(error);
    } finally {
      loading.hide();
    }
  }, [user]);

  useEffect(() => {
    getAssinaturaAtiva();
  }, [getAssinaturaAtiva]);

  return (
    <AssinaturaContext.Provider value={{ assinatura, setAssinatura }}>
      {children}
    </AssinaturaContext.Provider>
  );
};

export default AssinaturaAtivaContext;
