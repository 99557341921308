const traducaoBanco = (banco: string | undefined): string => {
  if (!banco) return '';

  switch (banco) {
    case '001':
      return 'Banco do Brasil';
    case '237':
      return 'Bradesco';
    case '341':
      return 'Itaú';
    case '999':
      return 'Conta transitória';
    default:
      return banco;
  }
};

export default traducaoBanco;
