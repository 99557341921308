import { createStore } from 'redux';
import throttle from 'lodash/throttle';
import { loadState } from './local-storage';
import persistState from './persist-state';
import { StateRedux, Action } from './typeRedux';

const INITIAL_STATE = {
  tokenAuth: null,
  user: null,
  representante: null,
  acesso: '',
  typeConnection: '',
};

const userRedux = (state: StateRedux = INITIAL_STATE, action: Action): StateRedux => {
  switch (action.type) {
    case 'TOKEN_AUTH':
      return {
        ...state,
        tokenAuth: action.tokenAuth,
      };

    case 'USER':
      return {
        ...state,
        user: action.user,
      };

    case 'ACESSO':
      return {
        ...state,
        acesso: action.acesso,
      };

    case 'REPRESENTANTE':
      return {
        ...state,
        representante: action.representante,
      };

    case 'TYPE_CONNECTION':
      return {
        ...state,
        typeConnection: action.typeConnection,
      };

    default:
      return state;
  }
};

const persistedState = loadState();

const store = persistedState
  ? createStore(userRedux, persistedState)
  : createStore(userRedux);

store.subscribe(throttle(persistState(store), 1000));

export default store;
