import { ReactElement } from 'react';
import Lottie from 'react-lottie';
import animation from '../assets/empty.json';

interface Props {
  width?: number;
  height?: number;
}

const LottieEmpty = ({ width, height }: Props): ReactElement => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
    />
  );
};

LottieEmpty.defaultProps = {
  width: 400,
  height: 400,
};

export default LottieEmpty;
