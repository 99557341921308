/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';
import SpanRequired from './layout/SpanRequired';

interface Props {
  tipoPix: string;
  onChangeTipoChave: (e: ChangeEvent<HTMLSelectElement>) => void;
  className: string;
  origem: 'Configuração' | 'Cadastro' | 'Flagship';
  classNameSpan?: string;
  classNameInput?: string;
  disabled?: boolean;
}

const SelectTipoChavePix = ({
  tipoPix,
  onChangeTipoChave,
  className,
  classNameSpan,
  classNameInput,
  origem,
  disabled,
}: Props): ReactElement => (
  <div className={className}>
    {origem !== 'Cadastro' && (
      <span className={classNameSpan}>
        Tipo da chave Pix:
        <SpanRequired />
      </span>
    )}
    <div className={origem === 'Cadastro' ? '' : classNameInput}>
      <select
        id="tipo-chave-pix"
        className="form-control m-input"
        value={tipoPix}
        name="tipo-chave-pix"
        onChange={(e) => onChangeTipoChave(e)}
        disabled={disabled}
        required
      >
        <option value="" disabled>Seleciona o tipo da chave pix</option>
        {origem !== 'Flagship' ? <option value="cpf">CPF</option> : ''}
        <option value="cnpj">CNPJ</option>
        <option value="email">E-mail</option>
        <option value="celular">Número de celular</option>
        <option value="aleatoria">Chave aleatória</option>
      </select>
    </div>
  </div>
);

SelectTipoChavePix.defaultProps = {
  classNameInput: 'col-lg-6 col-md-9 col-sm-12',
  classNameSpan: 'col-form-label col-lg-3 col-sm-12',
  disabled: false,
};

export default SelectTipoChavePix;
