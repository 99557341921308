/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useEffect, ReactElement } from 'react';
import moment, { Moment } from 'moment';

interface Intervalo {
  dataInicial: string,
  dataFinal: string,
}

interface Props {
  id: string;
  dataInicial: string | undefined;
  dataFinal: string | undefined;
  onChange: (intervalo: Intervalo) => void;
}

const DATE_PICKER_LOCALE = {
  format: 'DD/MM/YYYY',
  applyLabel: 'Pesquisar',
  cancelLabel: 'Cancelar',
  fromLabel: 'De',
  toLabel: 'até',
  customRangeLabel: 'Selecionar período',
  daysOfWeek: ['Do', 'Se', 'Te', 'Qua', 'Qui', 'Se', 'Sa'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  firstDay: 1,
};

const RANGES = {
  Hoje: [moment(), moment()],
  Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Últimos 7 dias': [moment().subtract(6, 'days'), moment()],
  'Últimos 30 dias': [moment().subtract(29, 'days'), moment()],
  'Mês atual': [moment().startOf('month'), moment().endOf('month')],
  'Mês anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
};

const DatePickerRanger = ({
  id,
  dataInicial,
  dataFinal,
  onChange,
}: Props): ReactElement => {
  useEffect(() => {
    ($(`#${id}`) as any).daterangepicker({ // eslint-disable-line @typescript-eslint/no-explicit-any
      opens: 'left',
      startDate: moment(dataInicial, 'YYYY-MM-DD'),
      endDate: moment(dataFinal, 'YYYY-MM-DD'),
      locale: DATE_PICKER_LOCALE,
      ranges: RANGES,
    }, (momentInicial: Moment, momentFinal: Moment) => {
      onChange({
        dataInicial: momentInicial.format('YYYY-MM-DD'),
        dataFinal: momentFinal.format('YYYY-MM-DD'),
      });
    });

    $('.drp-selected').hide();
  }, [dataInicial, dataFinal, onChange, id]);

  return (
    <div className="pull-right" id={id}>
      <span className="datepickerranger m-subheader__daterange btn-md btn btn-secondary m-btn m-btn--icon" data-locale="pt-br">
        <span className="m-subheader__daterange-label">
          <span className="m-subheader__daterange-title" />
          <span className="m-subheader__daterange-date m--font-boldest m--margin-left-5 m--margin-right-5">
            <span>
              <span style={{ color: '#575962', marginInline: 10 }}>
                {dataInicial === dataFinal
                  ? `${moment(dataInicial).format('DD/MM/Y')} `
                  : `${moment(dataInicial).format('DD/MM/Y')} - ${moment(dataFinal).format('DD/MM/Y')} `}
              </span>
              <i className="fa-light fa-calendar" />
            </span>
          </span>
        </span>
      </span>
    </div>
  );
};

export default DatePickerRanger;
