import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

const Wrapper = ({ children }: Props): ReactElement => (
  <div
    className="m-grid__item m-grid__item--fluid m-wrapper"
    style={{ backgroundColor: '#f2f3f8', height: '100%' }}
  >
    {children}
  </div>
);

export default Wrapper;
