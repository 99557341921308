/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartOptions } from 'chart.js';

const buildOptionsLineChart = () => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },

      tooltip: {
        // Disable the on-canvas tooltip
        // enabled: false,
        mode: 'index',
        intersect: false,

        // external(context: {
        //   tooltip: any
        //   chart: { canvas: { getBoundingClientRect: () => any } }
        // }) {
        //   // Tooltip Element
        //   let tooltipEl = document.getElementById('chartjs-tooltip');

        //   // Create element on first render
        //   if (!tooltipEl) {
        //     tooltipEl = document.createElement('div');
        //     tooltipEl.id = 'chartjs-tooltip';
        //     tooltipEl.innerHTML = '<table></table>';
        //     document.body.appendChild(tooltipEl);
        //   }

        //   // Hide if no tooltip
        //   const tooltipModel = context.tooltip;
        //   // if (tooltipModel.opacity === 0) {
        //   //   tooltipEl.style.opacity = '1';
        //   //   return;
        //   // }

        //   // Set Text
        //   if (tooltipModel.body) {
        //     const title = tooltipModel.title || [''];
        //     const body = tooltipModel.body[0].lines;

        //     let innerHtml = '<thead>';
        //     innerHtml += `<tr><th class="tooltip-balao-title">${title[0]} Horas</th></tr>`;
        //     innerHtml += '</thead><tbody>';
        //     innerHtml += `<tr><td class="tooltip-balao-body">${body[0]} Operações</td></tr>`;
        //     innerHtml += '</tbody>';

        //     const tableRoot = tooltipEl.querySelector('table');

        //     if (!tableRoot) return;

        //     tableRoot.innerHTML = innerHtml;
        //   }

        //   const position = context.chart.canvas.getBoundingClientRect();

        //   // Display, position, and set styles for font
        //   tooltipEl.className = 'tooltip-balao';

        //   tooltipEl.style.opacity = '1';
        //   tooltipEl.style.position = 'absolute';
        //   tooltipEl.style.boxShadow = '2px 2px 3px rgb(0, 0, 0, 0.7)';
        //   tooltipEl.style.backgroundColor = 'white';
        //   tooltipEl.style.padding = '5px 10px';
        //   tooltipEl.style.pointerEvents = 'none';
        //   tooltipEl.style.textAlign = 'center';
        //   tooltipEl.style.borderRadius = '5px';

        //   tooltipEl.style.left = `${
        //     position.left + window.pageXOffset + tooltipModel.caretX - 60.5
        //   }px`;
        //   tooltipEl.style.top = `${
        //     position.top + window.pageYOffset + tooltipModel.caretY - 59
        //   }px`;
        // },
        // position: 'average',
      },
    },
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        right: 30,
        top: 30,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          beginAtZero: true,
          font: {
            size: 13,
            family: 'Montserrat, san-serif',
            weight: 300,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 13,
            family: 'Montserrat, san-serif',
            weight: 300,
          },
        },
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0.4,
      },
    },
    pointBackgroundColor: 'rgb(0, 189, 174)',
    pointBorderColor: 'rgb(0, 189, 174)',
    pointBorderWidth: 3,
  } as unknown as ChartOptions;

  return options;
};

export default buildOptionsLineChart;
