import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

const PortletHeadTools = ({ children }: Props): ReactElement => (
  <div className="m-portlet__head-tools">
    <ul className="m-portlet__nav">
      <li className="m-portlet__nav-item">
        {children}
      </li>
    </ul>
  </div>
);

export default PortletHeadTools;
