/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import VMasker from 'vanilla-masker';
import React, {
  useEffect, InputHTMLAttributes, ReactElement,
} from 'react';
import { getInputById } from '../../utils';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  setValor: (x: string) => void;
};

const InputMaskMoney: React.FC<InputProps> = ({ setValor, ...rest }): ReactElement => {
  useEffect(() => {
    const input = getInputById(rest.id || '');
    VMasker(input).maskMoney({ unit: 'R$' });
    input.onchange = () => setValor(input.value);
  }, [rest.id, setValor]);

  return (
    <input {...rest} />
  );
};

export default InputMaskMoney;
