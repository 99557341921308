import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ButtonGerarPix from './itens-topbar/ButtonGerarPix';
import DropdownPerfil from './itens-topbar/DropdownPerfil';
import Contador from './itens-topbar/Contador';
import CadastrarConfigPix from './itens-topbar/CadastrarConfigPix';
import { exibirErrorCatch, getTheme } from '../../utils';
import useConfigPix from '../../hooks/useConfigPix';
import PlanoAtivo from './itens-topbar/PlanoAtivo';
import store from '../../store';
import BaixarApp from './itens-topbar/BaixarApp';
import DividerHeader from './itens-topbar/DividerHeader';

const isSpeedPix = getTheme() === 'speedpix';

const TopbarHeader = (): ReactElement => {
  const { tokenAuth } = store.getState();
  const { configPix } = useConfigPix();
  const [isConfigPixCadastrada, setIsConfigPixCadastrada] = useState<boolean>(false);

  const checkCadastroConfigPix = useCallback(async () => {
    try {
      if (!tokenAuth) return;

      if (!configPix) return;

      setIsConfigPixCadastrada(true);
    } catch (error) {
      exibirErrorCatch(error);
    }
  }, [tokenAuth, configPix]);

  useEffect(() => {
    checkCadastroConfigPix();
  }, [checkCadastroConfigPix]);

  return (
    <div
      id="m_header_nav"
      className="m-stack__item m-stack__item--fluid m-header-head"
    >
      <div
        id="m_header_topbar"
        className="m-topbar m-stack m-stack--ver m-stack--general"
      >
        <div className="m-stack__item m-topbar__nav-wrapper">
          <ul
            id="header-subcontainer"
            className="m-topbar__nav m-nav m-nav--inline flex align-items-center m-0"
            style={{ maxWidth: '100%', paddingRight: 25 }}
          >
            <DividerHeader />
            {!isConfigPixCadastrada ? (
              <CadastrarConfigPix />
            ) : (
              <ButtonGerarPix />
            )}
            {isSpeedPix && (
              <>
                <DividerHeader />
                <BaixarApp />
                <DividerHeader />
                <PlanoAtivo />
              </>
            )}
            <DividerHeader />
            <Contador />
            <DividerHeader />
            <DropdownPerfil />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopbarHeader;
