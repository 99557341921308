/* @flow */

import type { StateColors } from '../@types/Colors';

const getCorFundoFotoUsuario = (nome: string): StateColors => {
  const primeiraLetra = nome.substr(0, 1);

  if (/[A-E]/i.test(primeiraLetra)) {
    return 'success';
  }

  if (/[F-J]/i.test(primeiraLetra)) {
    return 'info';
  }

  if (/[K-P]/i.test(primeiraLetra)) {
    return 'warning';
  }

  if (/[Q-U]/i.test(primeiraLetra)) {
    return 'danger';
  }

  return 'primary';
};

export default getCorFundoFotoUsuario;
