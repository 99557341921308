import { Chart } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import 'chartjs-plugin-datalabels';

interface Props {
  labels: string[]
  dadosGrafico: number[]
  options: ChartOptions
}

const LineChart = ({ labels, options, dadosGrafico }: Props) => {
  const data = {
    labels,
    datasets: [
      {
        data: dadosGrafico,
        borderColor: 'rgb(0, 189, 174)',
        fill: {
          target: 'origin',
          above: 'rgb(0, 189, 174, 0.1)',
        },
      },
    ],
  };

  return <Chart type="line" data={data} options={options} height={50} />;
};

export default LineChart;
