import { ReactElement, useCallback, useEffect } from 'react';
import Anchor from '../layout/Anchor';
import { logoColor, getTheme } from '../../utils';

const LogoHeader = (): ReactElement => {
  const activeTheme = getTheme();
  const handleSubHeader = () => {
    const headerNav = document.getElementById('m_header_nav');

    if (!headerNav) {
      return;
    }

    const { visibility } = window.getComputedStyle(headerNav, null);

    if (!visibility || visibility === 'hidden') {
      headerNav.style.visibility = 'visible';
      return;
    }

    headerNav.style.visibility = 'hidden';
  };

  const esconderSubHeader = useCallback(() => {
    const headerNav = document.getElementById('m_header_nav');
    const windowWidth = window.screen.width;

    if (!headerNav || windowWidth > 995) {
      return;
    }

    headerNav.style.display = 'none';
  }, []);

  useEffect(() => {
    esconderSubHeader();
  }, [esconderSubHeader]);

  return (
    <div className="m-stack__item m-brand m-brand--skin-light">
      <div className="m-stack m-stack--ver m-stack--general">
        <div className="m-stack__item m-stack__item--middle m-brand__logo">
          <a
            href={`https://www.${activeTheme}.com.br`}
            className="m-brand__logo-wrapper"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Logo"
              src={`/theme/dist/app/media/img/logos/${logoColor}`}
              style={{ marginLeft: '-20px', width: 180, height: 60 }}
            />
          </a>
        </div>
        <div className="m-stack__item m-stack__item--middle m-brand__tools">
          <Anchor
            id="m_aside_left_minimize_toggle"
            className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
          >
            <span />
          </Anchor>
          <Anchor
            id="m_aside_left_offcanvas_toggle"
            className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
          >
            <span />
          </Anchor>
          <Anchor
            id="m_aside_header_topbar_mobile_toggle"
            className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
            onClick={handleSubHeader}
          >
            <i className="flaticon-more" />
          </Anchor>
        </div>
      </div>
    </div>
  );
};

export default LogoHeader;
