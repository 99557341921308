import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const ButtonGerarPix = (): ReactElement => (

  <li
    className="d-flex flex-row align-items-center pointer"
    data-toggle="tooltip"
    data-original-title="Plano ativo"
  >
    <Link to="/gerar-pix" className="m-d-flex flex-row align-items-center anchor-text-decoration-none">
      <div className="d-flex flex-row text-body" style={{ alignItems: 'end' }}>
        <img
          src="/theme/dist/app/media/img/logos/logo-pix.png"
          alt="Foto"
          style={{ width: 21, height: 21 }}
        />
        <span className="option-text">
          Gerar pix
        </span>
      </div>
    </Link>
  </li>
);

export default ButtonGerarPix;
