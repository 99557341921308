import { CSSProperties, ReactElement } from 'react';
import { colors } from '../../utils';

interface Props {
  color?: typeof colors;
  style?: CSSProperties;
}

const LoadingGrid = ({ color, style }: Props): ReactElement => (
  <div
    className="animated row justify-content-center align-items-center fadeInLeft alert"
    style={style}
  >
    <div className={`m-loader m-loader--lg m-loader--${color}`} style={{ width: 40, display: 'inline-block' }} />
  </div>
);

LoadingGrid.defaultProps = {
  color: 'brand',
  style: undefined,
};

export default LoadingGrid;
