/* eslint-disable arrow-body-style */
import { ReactElement } from 'react';
import Header from './Header';
import AsideMenu from './AsideMenu';
import Wrapper from './Wrapper';
import GridBody from './GridBody';
// import { connect } from '../../services/socket';
// import useUser from '../../hooks/useUser';

interface Props {
  children: ReactElement;
}

const ContainerRoot = ({ children }: Props): ReactElement => {
  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <Header />
      <GridBody>
        <>
          <AsideMenu />
          <Wrapper>
            {children}
          </Wrapper>
        </>
      </GridBody>
      <div className="m-scroll-top m-scroll-top--skin-top" data-toggle="m-scroll-top" data-scroll-offset="500" data-scroll-speed="300">
        <i className="la la-arrow-up" />
      </div>
    </div>
  );
};

export default ContainerRoot;
