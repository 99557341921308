import { ReactElement } from 'react';

interface Props {
  children: ReactElement
}

const Row = ({ children }: Props): ReactElement => (
  <div className="row">
    {children}
  </div>
);

export default Row;
