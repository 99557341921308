import { ReactElement } from 'react';
import classNames from 'classnames';

interface Props {
  children: ReactElement;
  animated?: 'fadeInUp' | 'fadeInDown' | 'fadeInLeft' | 'fadeInRight';
}

const Content = ({ children, animated }: Props): ReactElement => (
  <div
    className={classNames([
      'm-content',
      animated ? `animated ${animated}` : null,
    ])}
  >
    {children}
  </div>
);

Content.defaultProps = {
  animated: undefined,
};

export default Content;
