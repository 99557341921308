import logoBB from '../images/bancos/bb.png';
import logoItau from '../images/bancos/itau.png';
import logoBradesco from '../images/bancos/bradesco.png';
import logoFlagship from '../images/bancos/flagship.png';

const logoBanco = (codigoBanco: string): string => {
  switch (codigoBanco) {
    case '001':
      return logoBB;
    case '237':
      return logoBradesco;
    case '341':
      return logoItau;
    case '999':
      return logoFlagship;
    default:
      return '';
  }
};

export default logoBanco;
