/* eslint-disable no-tabs */
import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
	fechar?: MouseEventHandler;
	titulo: string;
	children: ReactNode;
	footer?: ReactNode;
	modalSm?: boolean;
	modalLg?: boolean;
	mostrar: boolean;
}

const Modal = ({
  fechar,
  titulo,
  children,
  footer,
  modalSm,
  modalLg,
  mostrar,
}: Props): ReactElement => (
  <div>
    <div
      className="modal-backdrop fade show"
      style={{ display: mostrar ? 'block' : 'none' }}
    />
    <div
      id="modal"
      className="modal fade show fadeInDown animated"
      tabIndex={-1}
      role="dialog"
      style={{ display: mostrar ? 'block' : 'none' }}
    >
      <div
        className={classNames([
          'modal-dialog modal-dialog-centered',
          modalSm && 'modal-sm',
          modalLg && 'modal-lg',
        ])}
        style={{
          alignItems: 'center', overflowY: 'initial',
        }}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4>{titulo}</h4>
            {!!fechar && (
              <button
                type="button"
                className="close"
                onClick={fechar}
                style={{ cursor: 'pointer' }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            )}
          </div>
          <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
            {children}
          </div>
          {footer}
        </div>
      </div>
    </div>
  </div>
);

Modal.defaultProps = {
  fechar: undefined,
  footer: undefined,
  modalSm: false,
  modalLg: false,
};

export default Modal;
