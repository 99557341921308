import { ReactElement } from 'react';

const Loading = (): ReactElement => (
  <div
    className="d-flex justify-content-center"
    style={{ marginTop: 15, paddingBottom: 15 }}
  >
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Loading;
