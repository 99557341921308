/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';

interface Props {
  setValue: (x: number | string) => void;
  value: string | number;
}

const SelectStatusContatoDemo = ({ setValue, value }: Props): ReactElement => (
  <select
    className="form-control m-input"
    value={value}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => (
      setValue(e.target.value)
    )}
  >
    <option disabled value="">Selecione um status</option>
    <option value={1}>Aguardando contato</option>
    <option value={2}>Aguardando resposta</option>
    <option value={3}>Finalizado</option>
    <option value="%%%">Todos</option>
  </select>
);

export default SelectStatusContatoDemo;
