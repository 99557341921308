/* eslint-disable max-len */
import { AxiosPromise } from 'axios';
import xhr from './xhr';
import {
  CreateUser,
  UserUpdate,
  ObjectGeneric,
  ConfigPix,
  AlterarSenha,
  GerarPIX,
  Operacao,
  ResultGetOperacao,
  TypeFilter,
  Mensagens,
  MensagensUpdate,
  EnviarOperacao,
  FilterDatas,
  QuantidadeOperacoes,
  ResultGetHistoricoResgate,
  StatusCadastroFlagship,
  CadastroFlagship,
  UpdateConfigsFlagship,
  ResultGetoperacoesFlagship,
  NewPassword,
  SalvarContato,
  Plano,
  CreateCartaoIugu,
  Assinatura,
  CartaoCredito,
  InfosOperacoes,
  ResultGetoperacoesParaResgateFlagship,
  Recursos,
  FormaPagamento,
  DadosIugu,
  FaturaIugu,
  ResultOperacoesHistorico,
  Vendedor,
  CreateVendedor,
  ResultGetUsers,
  InformacoesAssinaturas,
  User,
  ResultGetContatosDemo,
  ContatoDemo,
  EnviarCredenciaisDemo,
  CreateRecursos,
  Flagship,
  RecursosRepresentantes,
  ParamsRecursosRepresentantes,
  UpdateRepresenante,
  AlterarSenhaRepresentante,
  Login,
  IResponseSelectContasVirtuais,
} from '../@types';
import { ResultGetUsuariosComOperacoes } from '../@types/Pix';
import { CreateRepresenante, Representante } from '../@types/Representantes';
import { getTheme } from './get-theme';
import { IResultViewClienteSaldoResgate } from '../@types/User';
import { IResponseExtrato, IResponseSaldoConta, ParamsExtrato } from '../@types/Flagship';

const aplicacao = getTheme();

/**
 * Admin
 */
const getUsersComInformacoesAssinaturas = (params: TypeFilter): AxiosPromise<ResultGetUsers> => (
  xhr.get('/admin/users/assinaturas', { params })
);
const getUsersCadastroPendente = (params: TypeFilter): AxiosPromise<ResultGetUsers> => (
  xhr.get('/admin/users/pendentes', { params })
);
const getUsersComInformacoesOperacoes = (params: TypeFilter & { usuariosId?: number }): AxiosPromise<ResultGetUsuariosComOperacoes> => (
  xhr.get('/admin/users/operacoes', { params })
);
const getgetContatosDemo = (params: TypeFilter): AxiosPromise<ResultGetContatosDemo> => (
  xhr.get('/admin/contatos-demo', { params })
);
const getUsuarioComContaFlagship = (params: TypeFilter): AxiosPromise<IResultViewClienteSaldoResgate> => (
  xhr.get('/admin/users/contas-flagship', { params })
);
const cadastrarAssinaturaUsuario = (userId: number, identifier: string): AxiosPromise<Assinatura> => (
  xhr.post(`/admin/assinatura/${userId}`, { identifier })
);
const cadastrarAtendimentoContatoDemo = (id: number, params: ObjectGeneric): AxiosPromise<ContatoDemo> => (
  xhr.put(`/admin/contatos-demo/${id}`, params)
);
const editarRecursoUsuario = (userId: number, params: CreateRecursos): AxiosPromise<Recursos> => (
  xhr.put(`/admin/recursos/${userId}`, params)
);
const editarAssinaturaUsuario = (userId: number, identifier: string): AxiosPromise<Assinatura> => (
  xhr.put(`/admin/assinatura/${userId}`, { identifier })
);
const cancelarAssinaturaUsuario = (userId: number): AxiosPromise<Assinatura> => (
  xhr.put(`/admin/assinatura/cancelar/${userId}`)
);
const deletarUsuario = (userId: number): AxiosPromise<void> => (
  xhr.delete(`/admin/user/pendente/${userId}`)
);
const deletarContatoDemo = (contatoId: number): AxiosPromise<void> => (
  xhr.delete(`/admin/contato-demo/${contatoId}`)
);
const deletarContaFlagship = (userId: number): AxiosPromise<void> => (
  xhr.delete(`/admin/conta-flagship/${userId}`)
);
const getExtratoPorUsuario = (userId: number, params: ParamsExtrato): AxiosPromise<IResponseExtrato> => (
  xhr.get(`/conta-virtual/extrato/${userId}`, { params })
);
const getContasVirtuaisSelect = (): AxiosPromise<IResponseSelectContasVirtuais[]> => (
  xhr.get('/admin/contas-virtuais')
);

const getSaldoFlagshipPorAccountId = (accountId: string): AxiosPromise<IResponseSaldoConta> => (
  xhr.get(`/admin/contas-virtuais/consultar-saldo/${accountId}`)
);

/**
 * Autenticação
 */
const login = (params: Login): AxiosPromise => xhr.post('/signin', params);
const createUser = (params: CreateUser): AxiosPromise<{ usuarioId: number }> => xhr.post('/signup', params);
const forgotPassword = (email: string, apelido: string, activeTheme: string): AxiosPromise => xhr.post('/redefine-password', { email, apelido, aplicacao: activeTheme });
const alterarSenha = (params: AlterarSenha): AxiosPromise => xhr.patch('/alterar-senha', params);
const activateUser = (params: ObjectGeneric): AxiosPromise => (
  xhr.patch('/user/activate', params)
);
const newPassword = (id: number, params: NewPassword): AxiosPromise<void> => (
  xhr.patch(`/new-password/${id}`, params)
);

/**
 * Usuário
 */
const updateUser = (params: UserUpdate): AxiosPromise => xhr.put('/update-user', params);
const updateUserById = (userId: number, params: UserUpdate): AxiosPromise => xhr.put(`/update-user/${userId}`, params);

const sendCodigoWhatsapp = (email: string, telefone: string): AxiosPromise<ObjectGeneric> => (
  xhr.post('/enviar-codigo-whatsapp', { email, telefone })
);
const salvarContato = (params: SalvarContato): AxiosPromise<User> => (
  xhr.post('/salvar-contato', params)
);
const salvarWhatsapp = (telefone: string): AxiosPromise<ObjectGeneric> => (
  xhr.post('/salvar-telefone-empresa', { telefone })
);

/**
 * Configuração PIX
 */
const sendCodigoVerificacao = (params: ObjectGeneric): AxiosPromise<ObjectGeneric> => (
  xhr.post('/codigo-seguranca', { ...params, aplicacao })
);
const sendCodigoVerificacaoByEmail = (email: string): AxiosPromise<ObjectGeneric> => (
  xhr.post('/codigo-seguranca/email', { email, aplicacao })
);
const getConfigPix = (): AxiosPromise<ConfigPix> => (
  xhr.get('/config-pix')
);
const getConfiguracoes = (): AxiosPromise<ConfigPix[]> => (
  xhr.get('/configuracoes-pix')
);
const getConfigPixByCodigoBanco = (codigoBanco: string): AxiosPromise<ConfigPix> => (
  xhr.get(`/config-pix/banco/${codigoBanco}`)
);
const createConfigPix = (params: ObjectGeneric): AxiosPromise<ConfigPix> => (
  xhr.post('/config-pix', params)
);
const createConfigPixComCertificado = (params: FormData): AxiosPromise<ConfigPix> => (
  xhr.post('/config-pix/certificado', params)
);
const updateConfigPix = (id: number, params: ObjectGeneric): AxiosPromise<ConfigPix> => (
  xhr.put(`/config-pix/${id}`, params)
);
const ativarConfig = (id: number): AxiosPromise<ConfigPix> => (
  xhr.put(`/config-pix/ativar/${id}`)
);

/**
 * Operações PIX
 */
const getOperacoesPorHora = (params: TypeFilter): AxiosPromise<{ hora: string, quantidade: string }[]> => (
  xhr.get('/operacoes-pix/por-hora', { params })
);
const getAllOperacoes = (params?: TypeFilter): AxiosPromise<{
  operacoes: Operacao[], totalPaginas: number;
}> => (
  xhr.get('/operacoes-pix/all', { params })
);
const getOperacoesPix = (params?: TypeFilter): AxiosPromise<ResultGetOperacao> => (
  xhr.get('/operacoes-pix', { params })
);
const getOperacoesByUserId = (userId: number, params: TypeFilter): AxiosPromise<ResultGetOperacao> => (
  xhr.get(`/operacoes-pix/usuario/${userId}`, { params })
);
const getQuantidadePix = (): AxiosPromise<QuantidadeOperacoes> => (
  xhr.get('/operacoes-pix/quantidade')
);
const verificarPagamento = (txid: string): AxiosPromise<Operacao> => (
  xhr.get(`/verificar-pagamento/${txid}`)
);
const verificarPagamentos = (params?: TypeFilter): AxiosPromise<ResultGetOperacao> => (
  xhr.get('/verificar-pagamentos', { params })
);
const getOperacaoPixByTxid = (txid: string): AxiosPromise<Operacao> => (
  xhr.get(`/operacao-pix/${txid}`)
);
const getUltimasOperacoes = (params: FilterDatas): AxiosPromise<Operacao[]> => (
  xhr.get('/ultimas-operacoes', { params })
);
const getInformacoesOperacoes = (params: TypeFilter & { usuariosId?: number }): AxiosPromise<InfosOperacoes> => (
  xhr.get('/informacoes-operacoes', { params })
);
const criarCobrancaPIX = (params: GerarPIX): AxiosPromise<Operacao> => (
  xhr.post('/gerar-pix', params)
);
const enviarOperacao = (params: EnviarOperacao): AxiosPromise<Operacao> => (
  xhr.post('/enviar-operacao', params)
);
const updateOperacaoPix = (txid: string, params: ObjectGeneric): AxiosPromise<Operacao> => (
  xhr.put(`/editar-operacao/${txid}`, params)
);
const darBaixaOperacao = (txid: string): AxiosPromise<Operacao> => (
  xhr.put(`/dar-baixa-operacao/${txid}`)
);
const cancelarOperacao = (txid: string): AxiosPromise<Operacao> => (
  xhr.put(`/cancelar-operacao/${txid}`)
);

/**
 * Mensagens
 */
const getMensagens = (): AxiosPromise<Mensagens> => (
  xhr.get('/mensagens')
);
const updateMensagens = (params: MensagensUpdate): AxiosPromise<Mensagens> => (
  xhr.put('/mensagens', params)
);

/**
 * Conta transitória
 */
const getConfiguracoesFlagship = (): AxiosPromise<Flagship> => (
  xhr.get('/conta-virtual/configuracoes')
);
const getHistoricoResgate = (params: TypeFilter): AxiosPromise<ResultGetHistoricoResgate> => (
  xhr.get('/conta-virtual/historico-resgate', { params })
);
const getOperacoesByHistoricoId = (idHistorico: number, params: TypeFilter): AxiosPromise<ResultOperacoesHistorico> => (
  xhr.get(`/conta-virtual/historico-resgate/operacoes/${idHistorico}`, { params })
);
const getEtapaCadastroFlagship = (): AxiosPromise<{ etapa: StatusCadastroFlagship }> => (
  xhr.get('/conta-virtual/etapa-cadastro')
);
const getDadosFlagship = (): AxiosPromise<CadastroFlagship> => (
  xhr.get('/conta-virtual/dados-cadastrados')
);
const getOperacoesFlagship = (params: TypeFilter): AxiosPromise<ResultGetoperacoesFlagship> => (
  xhr.get('/conta-virtual/operacoes', { params })
);
const getOperacoesParaResgateFlagship = (params: TypeFilter): AxiosPromise<ResultGetoperacoesParaResgateFlagship> => (
  xhr.get('/conta-virtual/operacoes-para-resgate', { params })
);
const cadastroFlagship = (params: CadastroFlagship): AxiosPromise<void> => (
  xhr.post('/conta-virtual', params)
);
const resgatarSaldoFlagship = (): AxiosPromise<void> => (
  xhr.post('/conta-virtual/resgatar')
);
const resgatarSaldoFlagshipPorUsuariosId = (usuariosId: number): AxiosPromise<void> => (
  xhr.post(`/conta-virtual/resgatar/${usuariosId}`)
);
const verificarPagamentosFlagship = (): AxiosPromise<void> => (
  xhr.post('/conta-virtual/verificar-pagamentos')
);
const updateConfigsPixFlagship = (id: number, params: ObjectGeneric): AxiosPromise<ConfigPix> => (
  xhr.put(`/conta-virtual/config-pix/${id}`, params)
);
const updateConfigsFlagship = (params: UpdateConfigsFlagship): AxiosPromise<Flagship> => (
  xhr.put('/conta-virtual/configuracoes', params)
);

/**
 * Plano
*/
const isSpeedPix = getTheme() === 'speedpix' ? 1 : 0;
const getPlanos = (): AxiosPromise<Plano[]> => xhr.get('/planos', { params: { isSpeedPix } });
// const getPlanos = (): AxiosPromise<Plano[]> => xhr.get('/planos');

/**
 * Assinaturas
 */
const getAssinaturaByPlanoId = (planosId: number):AxiosPromise<Assinatura> => (
  xhr.get(`/assinatura/${planosId}`)
);
const getAssinaturaAtiva = ():AxiosPromise<Assinatura> => (
  xhr.get('/assinatura-ativa')
);
const getInformacoesAssinaturas = (params: TypeFilter): AxiosPromise<InformacoesAssinaturas> => (
  xhr.get('/assinaturas/informacoes', { params })
);
const assinarPlano = (planosId: number):AxiosPromise<Assinatura> => (
  xhr.post('/assinaturas', { planosId })
);
const cancelarAssinatura = (assinaturasId: number):AxiosPromise<Assinatura> => (
  xhr.post('/cancelar-assinatura', { assinaturasId })
);
const pagarFatura = (assinaturasId: number): AxiosPromise<Assinatura> => (
  xhr.post(`/assinatura/pagar-fatura/${assinaturasId}`)
);
const alterarAssinatura = (planosId: number):AxiosPromise<Assinatura> => (
  xhr.put('/alterar-assinatura', { planosId })
);
const reativarAssinatura = (assinaturasId: number):AxiosPromise<Assinatura> => (
  xhr.put('/reativar-assinatura', { assinaturasId })
);

/**
 * Iugu
 */
const getDadosIugu = (): AxiosPromise<DadosIugu> => (
  xhr.get('/iugu/dados')
);
const getCartaoPadraoIugu = (): AxiosPromise<CartaoCredito> => (
  xhr.get('/iugu/cartao-credito-padrao')
);
const getCartoesCadastrados = (): AxiosPromise<CartaoCredito[]> => (
  xhr.get('/iugu/cartoes-cadastrados')
);
const cadastroCartaoIugu = (params: CreateCartaoIugu): AxiosPromise<void> => (
  xhr.post('/iugu/cartao-credito', params)
);
const alterarCartaoPadrao = (id: string): AxiosPromise<void> => (
  xhr.put(`/iugu/cartao-credito-padrao/${id}`)
);
const deleteCartao = (id: string):AxiosPromise<void> => (
  xhr.delete(`/iugu/cartao-credito/${id}`)
);
const getFaturaByAssinaturaId = (id: number): AxiosPromise<FaturaIugu> => (
  xhr.get(`/iugu/fatura/${id}`)
);

/**
 * Recursos
 */
const getRecursos = (): AxiosPromise<Recursos> => (
  xhr.get('/recursos')
);

/**
 * Forma pagamento
 */
const getFormaPagamento = ():AxiosPromise<FormaPagamento> => (
  xhr.get('/forma-pagamento')
);

/**
 * Vendedores
 */
const getVendedores = ():AxiosPromise<Vendedor[]> => (
  xhr.get('/vendedores')
);
const createVendedor = (params: CreateVendedor):AxiosPromise<Vendedor> => (
  xhr.post('/vendedores', params)
);
const alterarVendedor = (id: number, params: CreateVendedor):AxiosPromise<Vendedor> => (
  xhr.put(`/vendedores/${id}`, params)
);
const deletarVendedor = (id: number):AxiosPromise<void> => (
  xhr.delete(`/vendedores/${id}`)
);

/**
 * Demo
 */
const sendCredenciaisDemo = (params: EnviarCredenciaisDemo): AxiosPromise<void> => (
  xhr.post('/demo/enviar-credenciais', params)
);

/**
 * Representantes
 */
const getRepresentantes = (params: TypeFilter): AxiosPromise<Representante[]> => (
  xhr.get('/representantes', { params })
);
const getRepresentantesMaster = (usuarioId: number): AxiosPromise<Representante[]> => (
  xhr.get('/representantes-master', { params: { usuarioId } })
);
const cadastrarRepresentante = (params: CreateRepresenante): AxiosPromise<Representante> => (
  xhr.post('/representantes', params)
);
const updateRepresentante = (idRepresentante: number, params: UpdateRepresenante): AxiosPromise<Representante> => (
  xhr.put(`/representantes/${idRepresentante}`, params)
);
const ativarRepresentante = (idRepresentante: number): AxiosPromise<Representante> => (
  xhr.put(`/representantes/ativar/${idRepresentante}`)
);
const desativarRepresentante = (idRepresentante: number): AxiosPromise<Representante> => (
  xhr.put(`/representantes/desativar/${idRepresentante}`)
);
const adminAlterarSenhaRepresentante = (idRepresentante: number, senha: string): AxiosPromise<Representante> => (
  xhr.put(`/representantes/alterar-senha/by-admin/${idRepresentante}`, { senha })
);
const alterarSenhaRepresentante = (idRepresentante: number, params: AlterarSenhaRepresentante): AxiosPromise<Representante> => (
  xhr.put(`/representantes/alterar-senha/${idRepresentante}`, params)
);

/**
 * Recursos Representantes
 */
const getRecursosRepresentantes = (): AxiosPromise<RecursosRepresentantes> => (
  xhr.get('/recursos-representantes')
);
const createRecursosRepresentantes = (params: ParamsRecursosRepresentantes): AxiosPromise<RecursosRepresentantes> => (
  xhr.post('/recursos-representantes', params)
);
const updateRecursosRepresentantes = (params: ParamsRecursosRepresentantes): AxiosPromise<RecursosRepresentantes> => (
  xhr.put('/recursos-representantes', params)
);

export default {
  login,
  createUser,
  activateUser,
  alterarSenha,
  criarCobrancaPIX,
  forgotPassword,
  updateUser,
  updateUserById,
  sendCodigoVerificacao,
  getConfigPix,
  getConfiguracoes,
  createConfigPix,
  updateConfigPix,
  ativarConfig,
  getOperacaoPixByTxid,
  getOperacoesPorHora,
  getAllOperacoes,
  getOperacoesPix,
  updateOperacaoPix,
  verificarPagamento,
  getConfigPixByCodigoBanco,
  getMensagens,
  updateMensagens,
  enviarOperacao,
  getQuantidadePix,
  getHistoricoResgate,
  getEtapaCadastroFlagship,
  cadastroFlagship,
  getDadosFlagship,
  resgatarSaldoFlagship,
  resgatarSaldoFlagshipPorUsuariosId,
  updateConfigsFlagship,
  getOperacoesFlagship,
  updateConfigsPixFlagship,
  verificarPagamentosFlagship,
  newPassword,
  sendCodigoWhatsapp,
  salvarContato,
  getPlanos,
  createConfigPixComCertificado,
  cadastroCartaoIugu,
  getCartaoPadraoIugu,
  getAssinaturaByPlanoId,
  assinarPlano,
  getAssinaturaAtiva,
  cancelarAssinatura,
  alterarAssinatura,
  getCartoesCadastrados,
  deleteCartao,
  alterarCartaoPadrao,
  reativarAssinatura,
  getUltimasOperacoes,
  getInformacoesOperacoes,
  salvarWhatsapp,
  verificarPagamentos,
  getOperacoesParaResgateFlagship,
  getRecursos,
  darBaixaOperacao,
  cancelarOperacao,
  getFormaPagamento,
  getDadosIugu,
  getFaturaByAssinaturaId,
  pagarFatura,
  getOperacoesByHistoricoId,
  getVendedores,
  createVendedor,
  alterarVendedor,
  deletarVendedor,
  getUsersComInformacoesAssinaturas,
  getInformacoesAssinaturas,
  getOperacoesByUserId,
  getUsersComInformacoesOperacoes,
  sendCredenciaisDemo,
  sendCodigoVerificacaoByEmail,
  getgetContatosDemo,
  cadastrarAtendimentoContatoDemo,
  editarRecursoUsuario,
  editarAssinaturaUsuario,
  cadastrarAssinaturaUsuario,
  cancelarAssinaturaUsuario,
  getUsersCadastroPendente,
  deletarUsuario,
  deletarContatoDemo,
  getConfiguracoesFlagship,
  getRepresentantes,
  cadastrarRepresentante,
  getRecursosRepresentantes,
  createRecursosRepresentantes,
  updateRecursosRepresentantes,
  updateRepresentante,
  ativarRepresentante,
  desativarRepresentante,
  alterarSenhaRepresentante,
  adminAlterarSenhaRepresentante,
  getUsuarioComContaFlagship,
  deletarContaFlagship,
  getRepresentantesMaster,
  getExtratoPorUsuario,
  getContasVirtuaisSelect,
  getSaldoFlagshipPorAccountId,
};
