import { useContext } from 'react';
import { ContextRecursosRepresentantes, StateRecursos } from '../context/RecursosRepresentantesContext';

const useRecursosRepresentantes = (): StateRecursos => {
  const context = useContext(ContextRecursosRepresentantes);

  return context;
};

export default useRecursosRepresentantes;
