/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
}

const Input: React.FC<InputProps> = ({ name, ...props }) => {
  const inputRef = useRef(null);
  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <input ref={inputRef} defaultValue={defaultValue} {...props} />
      <span style={{ fontSize: 12 }} className="m--font-danger">{error}</span>
    </>
  );
};

Input.defaultProps = {
  name: undefined,
};

export default Input;
