import { Store } from 'redux';
import { saveState } from './local-storage';
import getPersistedState from './get-persisted-state';
import { StateRedux, Action } from './typeRedux';

export type AppStore = Store<StateRedux, Action>;

const persistState = (store: AppStore) => (): void => (
  saveState(getPersistedState(store.getState()))
);

export default persistState;
