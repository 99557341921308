/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';

interface Props {
  setValue: (x: number | string) => void;
  value: string | number;
}

const SelectStatusCadastroPendente = ({ setValue, value }: Props): ReactElement => (
  <select
    id="select-status-cadastro-usuario"
    name="select-status-cadastro-usuario"
    className="form-control m-input"
    value={value}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => (
      setValue(e.target.value)
    )}
  >
    <option disabled value="">Selecione um status</option>
    <option value="email_pendente">Ativação por email pendente</option>
    <option value="whatsapp_pendente">Cadastro do contato pendente</option>
    <option value="%%%">Todos</option>
  </select>
);

export default SelectStatusCadastroPendente;
