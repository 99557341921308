/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import SweetAlerts, { SweetAlertResult } from 'sweetalert2';
import get from 'lodash/get';
import { ErrorCatch } from '../@types';

const swal = SweetAlerts.mixin({
  confirmButtonColor: '#af5bf1',
  cancelButtonText: 'Cancelar',
  reverseButtons: true,
});

const success = (timer = 5000) => swal.mixin({
  icon: 'success',
  toast: true,
  timer,
  showConfirmButton: false,
  position: 'top-end',
});

const error = swal.mixin({
  icon: 'error',
  toast: true,
  timer: 5000,
  showConfirmButton: false,
  position: 'top-end',
});

const warn = swal.mixin({
  icon: 'warning',
  toast: true,
  timer: 10000,
  showConfirmButton: false,
  position: 'top-end',
});

export const errorCatch = error.mixin({
  position: 'bottom',
  timer: 10000,
});

export const exibirToastSucesso = (
  mensagem: string,
  timer?: number,
): Promise<SweetAlertResult> => success(timer).fire(mensagem);

export const exibirToastWarn = (mensagem: string): Promise<SweetAlertResult> => warn.fire(mensagem);

export const exibirToastError = (
  mensagem: string,
): Promise<SweetAlertResult> => error.fire(mensagem);

export const exibirErrorCatch = (e: unknown, message = 'Algo deu errado, tente novamente mais tarde'): Promise<SweetAlertResult> => {
  const err = e as ErrorCatch;

  if (err?.response && err.response.data.validation) {
    return errorCatch.fire(get(
      err,
      'response.data.validation.body.message',
      message,
    ));
  }

  return errorCatch.fire(get(
    err,
    'response.data.message',
    message,
  ));
};
