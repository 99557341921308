/* eslint-disable no-unused-vars */
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../../components';
import { getTheme } from '../../../utils';

interface Props {
  visible: boolean;
  setVisible: (x: boolean) => void;
}

const isSpeedPix = getTheme() === 'speedpix';

const ModalOpcoesConfigPix = ({ visible, setVisible }: Props):ReactElement => (
  <Modal
    mostrar={visible}
    titulo="Cadastrar chave pix"
    fechar={() => setVisible(false)}
  >
    <div className="col-lg-16">
      <form
        className="m-form"
        style={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div className="m-portlet__body" style={{ paddingTop: 15, paddingBottom: 35 }}>
          <h5 className="m--font-primary">
            <i className="fa flaticon-imac" style={{ fontSize: 20 }} />
            &nbsp;
            Conta transitória
          </h5>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <p>
              Nessa opção você poderá criar uma conta transitória para gerar e
              receber as operações pix, podendo creditar a hora que desejar o
              valor recebido.
            </p>
            <div className="m-portlet__foot m-portlet__foot--fit">
              <div
                className="m-form__actions m-form__actions--right"
                style={{ padding: 0 }}
              >
                <Link
                  to="/conta-virtual"
                  type="submit"
                  className="btn btn-sm btn-primary m-btn m-btn--custom m-btn--icon border-radius-10"
                  onClick={() => setVisible(false)}
                >
                  <span>
                    <span>
                      Visualizar
                    </span>
                      &nbsp;&nbsp;
                    <i className="la la-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isSpeedPix && (
          <>
            <div
              id="divider"
              style={{
                border: '1px solid rgb(102 102 102 / 28%)',
                color: '#eaeaea',
              }}
            />
            <div className="m-portlet__body" style={{ paddingTop: 15, paddingBottom: 5 }}>
              <h5 className="m--font-accent">
                <i className="fa flaticon-piggy-bank" style={{ fontSize: 25 }} />
                &nbsp;
                Bancos tradicionais
              </h5>
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <p>
                  Nessa opção você poderá se integrar ao Banco do Brasil ou criar
                  uma conta sem integração.
                  <br />
                </p>
                <div className="m-portlet__foot m-portlet__foot--fit">
                  <div
                    className="m-form__actions m-form__actions--right"
                    style={{ padding: 0 }}
                  >
                    <Link
                      to="/usuario?pagina=configuracao-pix"
                      type="submit"
                      className="btn btn-sm btn-accent m-btn m-btn--custom m-btn--icon border-radius-10"
                      onClick={() => setVisible(false)}
                    >
                      <span>
                        <span>
                          Visualizar
                        </span>
                      &nbsp;&nbsp;
                        <i className="la la-arrow-right" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          id="divider"
          style={{
            border: '1px solid rgb(102 102 102 / 28%)',
            color: '#eaeaea',
            marginTop: 30,
            marginBottom: 30,
          }}
        />
        <span style={{ fontSize: 13 }}>
          <b className="m--font-boldest">Obs</b>
          : As contas não integradas não possuem o serviço de consulta do recebimento
          do pagamento das operações.
        </span>
      </form>
    </div>
  </Modal>
);

export default ModalOpcoesConfigPix;
