import { getTheme } from './get-theme';

const isSpeedPix = getTheme() === 'speedpix';

interface Colors {
  success: string;
  warning: string;
  danger: string;
  info: string;
  primary: string;
  brand: string;
}

const colors: Colors = {
  success: isSpeedPix ? '#34bfa3' : '#ff8700',
  warning: '#ffb822',
  danger: '#f4516c',
  info: '#36a3f7',
  primary: '#5867dd',
  brand: '#716aca',
};

export default colors;
