/* eslint-disable import/prefer-default-export */

export const getTheme = (): string => {
  const { hostname } = document.location;
  const isSpeedPix = hostname.includes('speedpix');

  const currentTheme = isSpeedPix ? 'speedpix' : 'pixzero';

  document.body.setAttribute('data-theme', currentTheme);

  return currentTheme;
};

export const logoColor = getTheme() === 'pixzero' ? 'logo-pixzero.png' : 'logo-speedpix.png';
export const logoWhite = getTheme() === 'pixzero' ? 'logo-pixzero-branco.png' : 'logo-speedpix.png';
