/* eslint-disable react/jsx-props-no-spreading */
import {
  useEffect, ReactElement, InputHTMLAttributes, FC,
} from 'react';
import inputmask from 'inputmask';
import { getInputById } from '../../utils';
import { Input } from '../index';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const InputMaskData: FC<InputProps> = ({ ...rest }): ReactElement => {
  const inputMask = () => inputmask({ mask: ['99/99/9999'] });

  useEffect(() => {
    inputMask().mask(getInputById(rest.id || ''));
  }, [rest.id]);

  return rest.name ? <Input {...rest} /> : <input {...rest} />;
};

export default InputMaskData;
