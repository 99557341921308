/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';

interface Props {
  setValue: (x: number | string) => void;
  value: string | number;
}

const SelectFormaPagamento = ({ setValue, value }: Props): ReactElement => (
  <select
    id="select-forma-pagamento"
    className="form-control m-input"
    value={value}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => (
      setValue(e.target.value)
    )}
  >
    <option disabled value="">Selecione uma forma de pagamento</option>
    {/* <option value="iugu">Iugu</option> */}
    <option value="maxscalla">Maxscalla</option>
    <option value="%">Todos</option>
  </select>
);

export default SelectFormaPagamento;
