/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-lone-blocks */
import {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { Anchor } from '../..';
import useUser from '../../../hooks/useUser';
import useAssinaturaAtiva from '../../../hooks/useAssinaturaAtiva';
// import { onGeracaoPix } from '../../../services/socket';
import {
  api,
  exibirErrorCatch,
  getTheme,
} from '../../../utils';

interface Datas {
  dataInicial: Date,
  dataFinal: Date,
}

interface Informacoes {
  contratadas: number;
  disponiveis: number;
  excedentes: number;
  geradas: number;
  porcentagem: string;
  datas: Datas | undefined,
}

const StateInicial = {
  contratadas: 0,
  disponiveis: 0,
  excedentes: 0,
  geradas: 0,
  porcentagem: '0',
  datas: undefined,
};

const Contador = (): ReactElement => {
  const { user, tokenAuth } = useUser();
  const { assinatura } = useAssinaturaAtiva();
  const [informacoes, setInformacoes] = useState<Informacoes>(StateInicial);

  const isAssinaturaPlanoFull = assinatura && assinatura.plano && assinatura.plano.identifier === 'full';

  const handleInformacoes = (quantidade: number, dataInicial?: Date, dataFinal?: Date) => {
    if (!assinatura || !assinatura.plano) {
      setInformacoes({
        ...informacoes,
        geradas: quantidade,
        datas: dataInicial && dataFinal ? { dataInicial, dataFinal } : undefined,
      });
      return;
    }

    const contratadas = assinatura.plano.numeroOperacoes;

    const disponiveis = assinatura.plano.numeroOperacoes - quantidade >= 0
      ? assinatura.plano.numeroOperacoes - quantidade
      : 0;

    const excedentes = quantidade - assinatura.plano.numeroOperacoes >= 0
      ? quantidade - assinatura.plano.numeroOperacoes
      : 0;

    const porcentagem = Number((quantidade / contratadas) * 100) > 100
      ? '100'
      : Number((quantidade / contratadas) * 100).toFixed(2);

    setInformacoes({
      contratadas,
      geradas: quantidade,
      disponiveis,
      excedentes,
      porcentagem,
      datas: dataInicial && dataFinal ? { dataInicial, dataFinal } : undefined,
    });
  };

  const getQuantidadePix = useCallback(async () => {
    try {
      if (!user || !tokenAuth) return;

      const { data } = await api.getQuantidadePix();

      handleInformacoes(data.quantidade, data.datas.dataInicial, data.datas.dataFinal);
    } catch (error) {
      exibirErrorCatch(error, 'Algo deu errado ao buscar a quantidade de operações geradas');
    }
  }, [user, tokenAuth, assinatura]);

  const listenGeracao = useCallback(() => {
    // onGeracaoPix((qtd: number) => {
    //   handleInformacoes(qtd);
    // });
  }, [handleInformacoes]);

  useEffect(() => {
    listenGeracao();
  }, [listenGeracao]);

  useEffect(() => {
    getQuantidadePix();
  }, [getQuantidadePix]);

  return (
    <li
      className="d-flex justify-content-center align-items-center pointer m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
      data-toggle="tooltip"
      data-original-title="Operações no mês"
      data-dropdown-toggle="click"
    >
      <Anchor className="m-d-flex flex-row align-items-center m-dropdown__toggle">
        <div className="d-flex flex-row text-body" style={{ alignItems: 'baseline', position: 'relative' }}>
          <i className="fa fa-solid fa-calendar-days" style={{ fontSize: '1.5rem', color: getTheme() === 'speedpix' ? 'rgb(0 189 174)' : '#e67d1d' }} />
          {assinatura && assinatura.plano && (
            <span className="badge-header">
              {informacoes.geradas}
            </span>
          )}
          <span className="option-text">
            Operações no mês
          </span>
        </div>
      </Anchor>
      <div className="m-dropdown__wrapper">
        <span
          className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
          style={{ color: '#fff' }}
        />
        <div className="m-dropdown__inner">
          <div className="m-dropdown__body">
            <div className="m-dropdown__content">
              <ul className="m-nav m-nav--skin-light">
                <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                  {informacoes.datas && (
                  <>
                    <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <span className="text-muted m--font-bolder">Intervalo de cobrança</span>
                        <div>
                          <span className="m--font-bold text-muted">
                            {moment(informacoes.datas.dataInicial).format('DD/MM/YYYY')}
                          </span>
                          <span className="m--font-bold text-muted"> - </span>
                          <span className="m--font-bold text-muted">
                            {moment(informacoes.datas.dataFinal).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="m-nav__separator m-nav__separator--fit" />
                  </>
                  )}
                  {isAssinaturaPlanoFull ? null : (
                    <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="m--font-bold text-muted">Contratado</span>
                        <span className="m--font-bold text-muted">{informacoes.contratadas}</span>
                      </div>
                    </li>
                  )}
                  <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span className="m--font-bold text-muted">Gerado</span>
                      <span className="m--font-bold text-muted">{informacoes.geradas}</span>
                    </div>
                  </li>
                  {isAssinaturaPlanoFull ? null : (
                    <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="m--font-bold text-muted">Disponível</span>
                        <span className="m--font-bold m--font-success">{informacoes.disponiveis}</span>
                      </div>
                    </li>
                  )}
                  {isAssinaturaPlanoFull ? null : (
                    <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="m--font-bold text-muted">Excedente</span>
                        <span className="m--font-bold m--font-danger">{informacoes.excedentes}</span>
                      </div>
                    </li>
                  )}
                  {isAssinaturaPlanoFull ? null : (
                    <>
                      <li className="m-nav__separator m-nav__separator--fit" />
                      <li className="m-nav__item">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span className="m--font-bold text-muted">{`${informacoes.porcentagem}%`}</span>
                          <span style={{ color: '#9699a2' }}>operações utilizadas</span>
                        </div>
                      </li>
                      <li className="m-nav__item">
                        <div className="progress m-progress--sm">
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" style={{ width: `${informacoes.porcentagem}%` }} />
                        </div>
                      </li>
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Contador;
