/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import {
  useEffect, ReactElement, InputHTMLAttributes, FC, ChangeEvent,
} from 'react';
import VMasker from 'vanilla-masker';
import { getInputById, removeMascara } from '../../utils';
import { Input } from '../index';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const InputMaskTelefone: FC<InputProps> = ({ onChange, ...rest }): ReactElement => {
  const mascaras = ['(99) 9999-9999', '(99) 99999-9999'];

  const inputHandler = (masks: string[], max: number, event: { target: any; }) => {
    const input = event.target;
    const value = removeMascara(input.value);
    const mask = value.length > max ? 1 : 0;

    VMasker(input).unMask();
    VMasker(input).maskPattern(masks[mask]);
    VMasker.toPattern(value, masks[mask]);

    if (onChange) {
      onChange(event as ChangeEvent<HTMLInputElement>);
    }
  };

  const handleInputValue = () => {
    const input = getInputById(rest.id || '');

    const mask = input.value.length > 10 ? 1 : 0;

    VMasker(input).maskPattern(mascaras[mask]);

    input.addEventListener('input', inputHandler.bind(undefined, mascaras, 10), false);
  };

  useEffect(() => {
    handleInputValue();
  }, [rest.value]);

  return rest.name ? <Input {...rest} /> : <input {...rest} />;
};

export default InputMaskTelefone;
