import { useContext } from 'react';
import { ConfigPixContext, StateConfigPixAtiva } from '../context/ConfigPixAtivaContext';

const useConfigPix = (): StateConfigPixAtiva => {
  const context = useContext(ConfigPixContext);

  return context;
};

export default useConfigPix;
