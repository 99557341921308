/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Recursos } from '../@types';
import useAssinaturaAtiva from '../hooks/useAssinaturaAtiva';
import useUser from '../hooks/useUser';
import { api, exibirErrorCatch, loading } from '../utils';

interface Props {
  children: ReactNode;
}

export interface StateRecursos {
  recursos: Recursos | null,
  setRecursos: (x: Recursos) => void;
}

export const ContextRecursos = createContext({} as StateRecursos);

const RecursosContext = ({ children }: Props): ReactElement => {
  const { user } = useUser();
  const { assinatura } = useAssinaturaAtiva();
  const [recursos, setRecursos] = useState<Recursos | null>(null);

  const getRecursos = useCallback(async () => {
    try {
      if (!assinatura || !user) return;

      const { data } = await api.getRecursos();

      if (data) {
        setRecursos(data);
      }
    } catch (error) {
      exibirErrorCatch(error);
    } finally {
      loading.hide();
    }
  }, [assinatura]);

  useEffect(() => {
    getRecursos();
  }, [getRecursos]);

  return (
    <ContextRecursos.Provider value={{ recursos, setRecursos }}>
      {children}
    </ContextRecursos.Provider>
  );
};

export default RecursosContext;
