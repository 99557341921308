/* eslint-disable react/jsx-props-no-spreading */
import { HTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactElement;
  animated?: 'fadeInUp' | 'fadeInDown' | 'fadeInLeft' | 'fadeInRight';
  grid?: boolean;
}

const Portlet = ({
  children, animated, grid, ...rest
}: Props): ReactElement => (
  <div
    className={classnames([
      'm-portlet',
      'box-shadow-none',
      animated ? `animated ${animated}` : null,
      grid ? 'grid' : null,
      'border-radius-10',
    ])}
    {...rest}
  >
    {children}
  </div>
);

Portlet.defaultProps = {
  animated: undefined,
  grid: undefined,
};

export default Portlet;
