import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import { getTheme, handleIndex } from './utils';

getTheme();
handleIndex();

ReactDOM.render(
  <StrictMode>
    <Router />
  </StrictMode>,
  document.getElementById('root'),
);
