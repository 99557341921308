/* eslint-disable no-unused-vars */
import { ChangeEvent } from 'react';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { TipoChavePix } from '../@types';

type SetState = (x: string) => void;
type SetStateError = (x: TipoChavePix) => void;

const onChangeTipoChave = (e: ChangeEvent<HTMLSelectElement>, setState: SetState): void => {
  setState(e.target.value);
};

const onChangeCpf = (
  e: ChangeEvent<HTMLInputElement>,
  setState: SetState,
  setTypeError: SetStateError,
): void => {
  setState(e.target.value);

  const pixKey: string = e.target.value.replace(/\D/g, '');

  if (pixKey.length < 11 || !cpf.isValid(pixKey)) {
    setTypeError('cpf');
    return;
  }

  setTypeError('');
  setState(pixKey);
};

const onChangeCnpj = (
  e: ChangeEvent<HTMLInputElement>,
  setState: SetState,
  setTypeError: SetStateError,
): void => {
  setState(e.target.value);

  if (!e.target.value) {
    return;
  }

  const pixKey: string = e.target.value.replace(/\D/g, '');

  if (pixKey.length < 14 || !cnpj.isValid(pixKey)) {
    setTypeError('cnpj');
    return;
  }

  setTypeError('');
  setState(pixKey);
};

const onChangeEmail = (
  e: ChangeEvent<HTMLInputElement>,
  setState: SetState,
  setTypeError: SetStateError,
): void => {
  setState(e.target.value);

  const email = e.target.value.match(/[\w.]+@\w+\.\w{2,4}\.?\w{0,2}/ig);
  if (!email) {
    setTypeError('email');
    return;
  }

  setTypeError('');
  setState(email[0]);
};

const onChangeTelefone = (
  e: ChangeEvent<HTMLInputElement>,
  setState: SetState,
  setTypeError: SetStateError,
): void => {
  setState(e.target.value);

  const telefone: string = e.target.value
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace('-', '');

  if (telefone.length < 10) {
    setTypeError('telefone');
    return;
  }

  if (telefone.indexOf('55') === 1) {
    setTypeError('');
    setState(telefone);
    return;
  }

  setTypeError('');
  setState(`+55${telefone}`);
};

const onChangeAleatoria = (
  e: ChangeEvent<HTMLInputElement>,
  setState: SetState,
  setTypeError: SetStateError,
): void => {
  setState(e.target.value);

  if (e.target.value.length < 36) {
    setTypeError('aleatorio');
    return;
  }

  setTypeError('');
  setState(e.target.value);
};

export default {
  onChangeTipoChave,
  onChangeCpf,
  onChangeCnpj,
  onChangeEmail,
  onChangeTelefone,
  onChangeAleatoria,
};
