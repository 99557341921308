import { ReactElement } from 'react';

interface Props {
  title: string;
}
const PortletHeadCaption = ({ title }: Props): ReactElement => (
  <div className="ml-3 m-portlet__head-caption">
    <div className="m-portlet__head-title">
      <span className="m-portlet__head-icon m--hide">
        <i className="la la-gear" />
      </span>
      <h3 className="m-portlet__head-text">
        {title}
      </h3>
    </div>
  </div>
);

export default PortletHeadCaption;
