import { CSSProperties, ReactElement } from 'react';
import { Anchor } from '..';

interface Props {
  onClick: () => void;
  style?: CSSProperties;
  title: string;
}

const DropdownButtonList = ({ onClick, style, title }: Props): ReactElement => (
  <li className="m-nav__item">
    <span className="m-nav__link">
      <Anchor onClick={onClick} className="m-nav__link-text" style={style ?? { cursor: 'pointer' }}>
        {title}
      </Anchor>
    </span>
  </li>
);

DropdownButtonList.defaultProps = {
  style: undefined,
};

export default DropdownButtonList;
