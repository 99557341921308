/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';
import SpanRequired from './layout/SpanRequired';
import useRecursos from '../hooks/useRecursos';
import { getTheme } from '../utils';

interface Props {
  codigoBanco: string;
  onChangeTipoBanco: (e: ChangeEvent<HTMLSelectElement>) => void;
  className: string;
}

const SelectTipoBanco = ({
  codigoBanco,
  onChangeTipoBanco,
  className,
}: Props): ReactElement => {
  const isSpeedPix = getTheme() === 'speedpix';
  const { recursos } = useRecursos();
  const hasRecursoIntegracao = !!recursos?.integracaoBancos;

  return (
    <div className={className}>
      <span className="col-form-label col-lg-3 col-sm-12">
        Selecione seu banco:
        <SpanRequired />
      </span>
      <div className="col-lg-6 col-md-9 col-sm-12">
        <select
          id="tipo-banco"
          className="form-control m-input"
          value={codigoBanco}
          name="tipo-banco"
          onChange={(e) => onChangeTipoBanco(e)}
          style={{ borderRadius: 7 }}
          placeholder="Selecione seu banco"
        >
          <option disabled value="">Selecione seu banco</option>
          <option value="000">000 - Banco não integrado</option>
          {hasRecursoIntegracao && isSpeedPix && (
            <>
              <option value="001">001 - Banco do Brasil</option>
              <option value="237">237 - Bradesco</option>
              <option value="341">341 - Itaú</option>
            </>
          )}
        </select>
      </div>
    </div>
  );
};

export default SelectTipoBanco;
