/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-props-no-spreading */
import React, { AnchorHTMLAttributes, ReactElement } from 'react';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const ButtonGerarRelatorio: React.FC<AnchorProps> = ({ href, ...rest }): ReactElement => (
  <a
    href={href}
    target="_blank"
    className="btn btn-outline-info m-btn m-btn--custom btn-sm"
    media="print"
    {...rest}
  >
    <span style={{ marginRight: 10, fontWeight: 500 }}>
      Gerar relatório
    </span>
    <i className="fa-regular fa-print" />
  </a>
);

export default ButtonGerarRelatorio;
