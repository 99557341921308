import { CSSProperties, ReactElement } from 'react';
import { Button, DropdownButtonList } from '..';

type DropdownList = {
  id: number;
  onClick: () => void;
  title: string;
}

interface Props {
  items: DropdownList[];
  titleButton: string;
  disabled?: boolean;
  color?: string;
  style?: CSSProperties;
}

const DropdownButton = ({
  items,
  titleButton,
  disabled,
  color,
  style,
}: Props): ReactElement => (
  <div
    className="m-dropdown m-dropdown--inline m-dropdown--arrow"
    data-dropdown-toggle="hover"
    data-dropdown-persistent="true"
    aria-expanded="true"
    style={style}
  >
    <Button
      disabled={disabled}
      className={`m-dropdown__toggle dropdown-toggle btn btn-${color ?? 'success'} border-radius-10`}
    >
      {titleButton}
    </Button>

    <div className="m-dropdown__wrapper">
      <span className="m-dropdown__arrow m-dropdown__arrow--left" />
      <div className="m-dropdown__inner">
        <div className="m-dropdown__body">
          <div className="m-dropdown__content">
            <ul className="m-nav">
              {items.map((item) => (
                <DropdownButtonList
                  key={item.id}
                  onClick={item.onClick}
                  title={item.title}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

DropdownButton.defaultProps = {
  disabled: undefined,
  color: undefined,
  style: undefined,
};

export default DropdownButton;
