import { ReactElement } from 'react';
import LogoHeader from './LogoHeader';
import TopbarHeader from './TopbarHeader';

const Header = (): ReactElement => (
  <header
    id="header"
    className="m-grid__item m-header d-print-none m-topbar--on"
    style={{ backgroundColor: '#FFFFFF', zIndex: 1 }}
    data-minimize-offset={200}
    data-minimize-mobile-offset={200}
  >
    <div className="m-container m-container--fluid m-container--full-height">
      <div className="m-stack m-stack--ver m-stack--desktop">
        <LogoHeader />
        <TopbarHeader />
      </div>
    </div>
  </header>
);

export default Header;
