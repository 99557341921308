/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { RecursosRepresentantes } from '../@types';
import useUser from '../hooks/useUser';
import { api, exibirErrorCatch, loading } from '../utils';

interface Props {
  children: ReactNode;
}

export interface StateRecursos {
  recursosRepresentantes: RecursosRepresentantes | null,
}

export const ContextRecursosRepresentantes = createContext({} as StateRecursos);

const RecursosRepresentantesContext = ({ children }: Props): ReactElement => {
  const { user } = useUser();
  const [recursosRepresentantes, setRecursosRepresentantes] = useState<RecursosRepresentantes | null>(null);

  const getRecursosRepresentantes = useCallback(async () => {
    try {
      if (!user) return;

      const { data } = await api.getRecursosRepresentantes();

      if (data) {
        setRecursosRepresentantes(data);
      }
    } catch (error) {
      exibirErrorCatch(error);
    } finally {
      loading.hide();
    }
  }, []);

  useEffect(() => {
    getRecursosRepresentantes();
  }, [getRecursosRepresentantes]);

  return (
    <ContextRecursosRepresentantes.Provider value={{ recursosRepresentantes }}>
      {children}
    </ContextRecursosRepresentantes.Provider>
  );
};

export default RecursosRepresentantesContext;
