import { ReactElement, CSSProperties } from 'react';

interface Props {
  children: ReactElement;
  style?: CSSProperties;
  className?: string;
}

const Table = ({ children, style, className }: Props): ReactElement => (
  <table className={className} style={style}>
    {children}
  </table>
);

Table.defaultProps = {
  style: undefined,
  className: 'table table-hover table-striped',
};

export default Table;
