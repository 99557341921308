import { ReactElement, useState } from 'react';
import { Anchor } from '../..';
import ModalOpcoesConfigPix from '../../../pages/pix/components/ModalOpcoesConfigPix';

const CadastrarConfigPix = (): ReactElement => {
  const [showModalopcoes, setShowModalOpcoes] = useState<boolean>(false);

  return (
    <>
      <ModalOpcoesConfigPix
        visible={showModalopcoes}
        setVisible={setShowModalOpcoes}
      />
      <li
        className="d-flex flex-row align-items-center pointer"
        data-toggle="tooltip"
        data-original-title="Plano ativo"
      >
        <Anchor onClick={() => setShowModalOpcoes(true)} className="m-d-flex flex-row align-items-center anchor-text-decoration-none">
          <div className="d-flex flex-row text-body" style={{ alignItems: 'baseline' }}>
            <i className="fa fa-solid fa-warning" style={{ fontSize: '1.5rem', color: 'rgb(0 189 174)' }} />
            <span className="option-text">
              Chave pix
            </span>
          </div>
        </Anchor>
      </li>
    </>
  );
};

export default CadastrarConfigPix;
