/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  ReactElement, ChangeEvent, useState, useEffect, useCallback,
} from 'react';
import moment from 'moment';
import { Representante, TypeFilter, User } from '../@types';
import {
  Button,
  SelectFormaPagamento,
  SelectPlanoAssinatura,
  SelectSimNao,
  SelectStatusAssinatura,
  SelectStatusCadastroPendente,
  SelectStatusContatoDemo,
} from '.';
import DataListBanco from './DataListBanco';
import SelectRepresentantes from './SelectRepresentantes';

type Colunas = {
  title: string;
  value: string;
}
interface Props {
  setFilter: (value: TypeFilter) => void;
  colunas: Colunas[];
  filter?: TypeFilter;
  usuarioCorrente?: User | null;
  representantes?: Representante[];
}

const status = [
  {
    name: 'Selecione um status...',
    id: 0,
  },
  {
    name: 'Aguardando',
    id: 1,
  },
  {
    name: 'Em análise',
    id: 2,
  },
  {
    name: 'Pago',
    id: 3,
  },
  {
    name: 'Devolvida',
    id: 4,
  },
  {
    name: 'Cancelada',
    id: 5,
  },
  {
    name: 'Vencida',
    id: 6,
  },
];

const columnsPersonalizadas = [
  'etapas_id',
  'banco',
  'creditado',
  'test',
  'status',
  'status_cadastro',
  'status_assinatura',
  'forma_pagamento',
  'plano_assinatura',
  'ativo',
  'flagship.resgate_automatico',
  'representantes_id',
];

const Filter = ({
  setFilter, colunas, filter, usuarioCorrente, representantes,
}: Props): ReactElement => {
  const [value, setValue] = useState<string | number>('');
  const [column, setColumn] = useState<string>(colunas[0].value);
  const [isColumnPersonalizada, setIsColumnPersonalizada] = useState<boolean>(false);

  const isAdminUserLocation = window.location.search && !window.location.hash;

  const handleInput = useCallback(() => {
    if (columnsPersonalizadas.includes(column)) {
      setIsColumnPersonalizada(true);
      return;
    }

    setIsColumnPersonalizada(false);
  }, [column]);

  const limparFiltros = () => {
    setFilter({
      dataInicial: moment().startOf('month').format('YYYY-MM-DD'),
      dataFinal: moment().endOf('month').format('YYYY-MM-DD'),
      column: undefined,
      value: undefined,
      columnToOrder: 'id',
      order: 'ASC',
      page: 1,
      quantity: 10,
      etapasId: 0,
    });

    setColumn('');
    setValue('');
  };

  const preencherValores = useCallback(() => {
    if (!filter) return;

    if (filter.column) {
      setColumn(filter.column);
    }

    if (filter.value) {
      setValue(filter.value);
    }
  }, [filter]);

  useEffect(() => {
    preencherValores();
  }, [preencherValores]);

  useEffect(() => {
    handleInput();
  }, [handleInput]);

  return (
    <div className="m-form m--margin-bottom-10">
      <div className="form-group m-form__group">
        <form
          className="form-inline grid__filtro"
          onSubmit={(e) => {
            e.preventDefault();
            setFilter({ value, column });
          }}
        >
          <select
            name="quantidade"
            className="form-control"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => (
              setFilter({ quantity: Number(e.target.value), page: 1 })
            )}
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={100}>100</option>
          </select>
          {usuarioCorrente?.id !== 137 ? (
            <>
              <select
                name="coluna"
                className="form-control"
                id="colunaSelect"
                value={column}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => (
                  setColumn(e.target.value)
                )}
              >
                {colunas.map((coluna) => (
                  <option key={coluna.title} value={coluna.value}>{coluna.title}</option>
                ))}
              </select>
              {!isColumnPersonalizada && (
              <input
                id="filter-chave-pix"
                name="filter-chave-pix"
                type="search"
                className="form-control"
                placeholder="Digite aqui..."
                onInput={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value.replace(',', '.'))}
              />
              )}
              {column === 'banco' && (
              <DataListBanco setValue={setValue} />
              )}
              {column === 'creditado' && (
              <SelectSimNao setValue={setValue} value={value} />
              )}
              {column === 'ativo' && (
              <SelectSimNao setValue={setValue} value={value} />
              )}
              {column === 'test' && (
              <SelectSimNao setValue={setValue} value={value} />
              )}
              {column === 'status' && (
              <SelectStatusContatoDemo setValue={setValue} value={value} />
              )}
              {column === 'status_cadastro' && (
              <SelectStatusCadastroPendente setValue={setValue} value={value} />
              )}
              {column === 'status_assinatura' && (
              <SelectStatusAssinatura setValue={setValue} value={value} />
              )}
              {column === 'representantes_id' && (
              <SelectRepresentantes setValue={setValue} representantes={representantes} />
              )}
              {column === 'forma_pagamento' && (
              <SelectFormaPagamento setValue={setValue} value={value} />
              )}
              {column === 'plano_assinatura' && (
              <SelectPlanoAssinatura setValue={setValue} value={value} />
              )}
              {column === 'flagship.resgate_automatico' && (
              <SelectSimNao setValue={setValue} value={value} />
              )}
              {!isAdminUserLocation && (
              <select
                name="etapasId"
                className="form-control"
                placeholder="Selecione um status"
                onChange={(e: ChangeEvent<HTMLSelectElement>) => (
                  setFilter({ etapasId: Number(e.target.value) })
                )}
              >
                {status.map((s) => (
                  <option key={s.id} value={s.id}>{s.name}</option>
                ))}
              </select>
              )}
              <Button
                type="button"
                className="btn btn-success"
                onClick={() => setFilter({ value, column, page: 1 })}
              >
                <i className="fa-regular fa-search" />
              </Button>
              <Button
                type="reset"
                className="btn btn-info"
                onClick={() => limparFiltros()}
              >
                <i className="fa-regular fa-refresh" />
                {' '}
                Limpar
              </Button>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default Filter;

Filter.defaultProps = {
  filter: undefined,
  usuarioCorrente: {},
  representantes: [],
};
