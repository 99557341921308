/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes, ReactElement } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonVerificar: React.FC<ButtonProps> = ({ ...rest }): ReactElement => (
  <button type="button" className="btn btn-outline-primary m-btn m-btn--custom btn-sm" {...rest}>
    <span>
      <span style={{ marginRight: 10, fontWeight: 500 }}>
        Verificar operações
      </span>
      <i className="fa-regular fa-refresh" />
    </span>
  </button>
);

export default ButtonVerificar;
