/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';

interface Props {
  setValue: (x: number | string) => void;
  value: string | number;
}

const SelectPlanoAssinatura = ({ setValue, value }: Props): ReactElement => (
  <select
    id="select-plano-assinatura"
    name="select-plano-assinatura"
    className="form-control m-input"
    value={value}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => (
      setValue(e.target.value)
    )}
  >
    <option disabled value="">Selecione o plano</option>
    <option value="basic">Basic</option>
    <option value="full">Full</option>
    <option value="%">Todos</option>
  </select>
);

export default SelectPlanoAssinatura;
