/* eslint-disable react/jsx-props-no-spreading */
import { HTMLAttributes, ReactElement } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const Divider = ({ ...rest }: Props): ReactElement => (
  <div
    style={{ margin: '20px 0px', borderTop: '1px solid #bbb' }}
    {...rest}
  />
);

export default Divider;
