import { useContext } from 'react';
import { ContextRecursos, StateRecursos } from '../context/RecursosContext';

const useRecursos = (): StateRecursos => {
  const context = useContext(ContextRecursos);

  return context;
};

export default useRecursos;
