import { useContext } from 'react';
import { AssinaturaContext, StateAssinaturaAtiva } from '../context/AssinaturaAtivaContext';

const useAssinaturaAtiva = (): StateAssinaturaAtiva => {
  const context = useContext(AssinaturaContext);

  return context;
};

export default useAssinaturaAtiva;
