/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactElement } from 'react';
import { StateColors } from '../@types';
import { moneyMask } from '../utils';

interface Props {
  porcentagem: string;
  color: StateColors;
  width: string;
  title: string;
  numeroOperacoes: number;
  valor: number;
}

const Card = ({
  porcentagem, color, width, title, numeroOperacoes, valor,
}: Props): ReactElement => {
  const handlePluralOperacoes = (numero: number): string => {
    if (Number(numero) === 1) {
      return 'Operação';
    }

    return 'Operações';
  };

  return (
    <div style={{ width }} className="home-card">
      <div className="m-widget1">
        <div className="m-widget1__item">
          <div className="row m-row--no-padding align-items-flex-start" style={{ gap: 20 }}>
            <div className="col m--align-left">
              <h3 className="m-widget1__title">
                {title}
              </h3>
              <span className="col m-widget1__desc" style={{ paddingLeft: 0 }}>
                {moneyMask(valor)}
              </span>
            </div>
            <div className="col m--align-right" style={{ width: 100 }}>
              <span style={{ fontSize: 18 }} className={`m-widget1__number m--font-${color}`}>
                {numeroOperacoes}
                &nbsp;
                {handlePluralOperacoes(numeroOperacoes)}
              </span>
            </div>
          </div>
          <div className="m--space-20" />
          <div className="progress m-progress--sm">
            <div className={`progress-bar m--bg-${color}`} role="progressbar" style={{ width: porcentagem }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
