/* eslint-disable react/jsx-props-no-spreading */
import {
  useEffect,
  FC,
  InputHTMLAttributes,
  ReactElement,
  useRef,
} from 'react';
import { useField } from '@unform/core';
import inputMask from 'inputmask';
import { getInputById } from '../../utils';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: string;
}

const InputNumeroCartaoCredito: FC<InputProps> = ({
  name,
  ...props
}): ReactElement => {
  const inputRef = useRef(null);
  const { defaultValue, error } = useField(name);
  const cc = () => inputMask({ mask: '9999 9999 9999 9999' });

  useEffect(() => {
    cc().mask(getInputById(props.id || ''));
  }, [props.id]);

  return (
    <>
      <input ref={inputRef} defaultValue={defaultValue} {...props} />
      <span className="invalid">{error}</span>
    </>
  );
};

InputNumeroCartaoCredito.defaultProps = {
  className: '',
};

export default InputNumeroCartaoCredito;
