/* eslint-disable import/no-unresolved */
import { ReactElement } from 'react';
import { QRCode } from 'react-qr-svg';
import { Anchor } from '../..';
import logoPlayStore from '../../../images/play-store.png';
import { getTheme } from '../../../utils';

const BaixarApp = (): ReactElement => (
  <li
    className="d-flex justify-content-center align-items-center pointer m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
    data-toggle="tooltip"
    data-original-title="Baixe o app do Speedpix"
    data-dropdown-toggle="click"
  >
    <Anchor className="m-d-flex flex-row align-items-center m-dropdown__toggle">
      <div className="d-flex flex-row text-body" style={{ alignItems: 'baseline' }}>
        <i className="fa fa-download" style={{ fontSize: '1.5rem', color: getTheme() === 'speedpix' ? 'rgb(0 189 174)' : '#e67d1d' }} />
        <span className="option-text">
          Baixe o App
        </span>
      </div>
    </Anchor>
    <div className="m-dropdown__wrapper">
      <span
        className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
        style={{ color: '#fff' }}
      />
      <div className="m-dropdown__inner">
        <div className="m-dropdown__body">
          <div className="m-dropdown__content">
            <ul className="m-nav m-nav--skin-light">
              <li className="m-nav__item" style={{ paddingTop: 5, paddingBottom: 5 }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <span className="text-muted m--font-bolder">Para baixar o App Speedpix</span>
                  <span className="m--font-bold text-muted">
                    Escaneie o QR Code ou&nbsp;
                    <a
                      href="https://play.google.com/store/apps/details?id=com.maxscalla.speedpix"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Clique aqui
                    </a>
                  </span>
                  <span>
                    <QRCode
                      style={{
                        maxWidth: '150px',
                        width: '100%',
                        maxHeight: '150px',
                        height: 'auto',
                        marginTop: 20,
                      }}
                      value="https://play.google.com/store/apps/details?id=com.maxscalla.speedpix"
                    />
                  </span>
                  <span style={{ marginTop: 15 }}>
                    <img src={logoPlayStore} alt="Logo android" />
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
);

export default BaixarApp;
