const switchQrcode = (etapasId: number) => {
  switch (etapasId) {
    case 3:
      return 'qrcode-pago.png';
    case 5:
      return 'qrcode-cancelado.png';
    case 6:
      return 'qrcode-vencido.png';
    default:
      return 'pix-qr-test.png';
  }
};

const handleQrcodeImage = (etapasId: number): string => {
  const image = switchQrcode(etapasId);

  return `/images/pix/${image}`;
};

export default handleQrcodeImage;
