import {
  lazy, Suspense, ReactElement, useEffect,
} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PrivateRoute, Spinner } from './components';
import ContainerRoot from './components/container-root';
import store from './store';
import AssinaturaAtivaContext from './context/AssinaturaAtivaContext';
import ConfigPixAtivaContext from './context/ConfigPixAtivaContext';
import RecursosContext from './context/RecursosContext';
import RecursosRepresentantesContext from './context/RecursosRepresentantesContext';
import registrarModulosChartjs from './components/graficos/config/registrar-chart';

const Login = lazy(() => import('./pages/login'));
const Logout = lazy(() => import('./pages/login/Logout'));
const Home = lazy(() => import('./pages/home'));
const Configuracoes = lazy(() => import('./pages/configuracoes'));
const GerarPix = lazy(() => import('./pages/pix/GerarPix'));
const PixGerados = lazy(() => import('./pages/pix/PixGerados'));
const LinkPagamentoPix = lazy(() => import('./pages/pix/LinkPagamentoPix'));
const Flagship = lazy(() => import('./pages/flagship'));
const Planos = lazy(() => import('./pages/planos'));
const Documentacao = lazy(() => import('./pages/documentacao'));
const Admin = lazy(() => import('./pages/admin'));
const Usuarios = lazy(() => import('./pages/usuarios'));
const Relatorio = lazy(() => import('./pages/relatorio'));

const Routes = (): ReactElement => {
  useEffect(() => {
    registrarModulosChartjs();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense
          fallback={<Spinner style={{ top: '300px', display: 'inline-block' }} />}
        >
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/pix/:txid" exact component={LinkPagamentoPix} />
            <PrivateRoute path="/relatorio" exact component={Relatorio} />
            <AssinaturaAtivaContext>
              <ConfigPixAtivaContext>
                <RecursosContext>
                  <RecursosRepresentantesContext>
                    <ContainerRoot>
                      <>
                        <PrivateRoute path="/" exact component={Home} />
                        <PrivateRoute path="/gerar-pix" exact component={GerarPix} />
                        <PrivateRoute path="/pix-gerados" exact component={PixGerados} />
                        <PrivateRoute path="/usuario" exact component={Configuracoes} />
                        <PrivateRoute path="/conta-virtual" exact component={Flagship} />
                        <PrivateRoute path="/planos" exact component={Planos} />
                        <PrivateRoute path="/documentacao" exact component={Documentacao} />
                        <PrivateRoute path="/admin" exact component={Admin} />
                        <PrivateRoute path="/usuarios" exact component={Usuarios} />
                      </>
                    </ContainerRoot>
                  </RecursosRepresentantesContext>
                </RecursosContext>
              </ConfigPixAtivaContext>
            </AssinaturaAtivaContext>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

export default Routes;
