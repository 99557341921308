/* eslint-disable no-undef */
const url = 'https://www.receitaws.com.br';

const consultarReceita = (x: string) => $.ajax({
  url: `${url}/v1/cnpj/${x}`,
  method: 'GET',
  dataType: 'jsonp',
  crossDomain: true,
});

// eslint-disable-next-line
const getDadosByCnpj = (cnpj: string) => consultarReceita(cnpj);

export default getDadosByCnpj;
