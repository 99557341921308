const autoloadFiles = (): void => {
  const files: string[] = [
    // CUSTOM
    '/theme/dist/vendors/base/vendors.bundle.js',
    'theme/dist/demo/default/base/scripts.bundle.js',
  ];

  files.forEach((file) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = file;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });
};

export default autoloadFiles;
