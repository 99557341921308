import { useSelector } from 'react-redux';
import { StateRedux } from '../store/typeRedux';

const useUser = (): StateRedux => {
  const stateRedux = useSelector((state: StateRedux) => state);

  return stateRedux;
};

export default useUser;
