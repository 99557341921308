/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, AnchorHTMLAttributes } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jwt, { VerifyErrors } from 'jsonwebtoken';
import { SIGNATURE } from '../utils';
import useUser from '../hooks/useUser';
import useRecursosRepresentantes from '../hooks/useRecursosRepresentantes';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const PrivateRoute = ({ component: Component, ...rest }: AnchorProps & any): ReactElement => {
  const dispatch = useDispatch();
  const { tokenAuth, user, acesso } = useUser();
  const { recursosRepresentantes } = useRecursosRepresentantes();

  if (!tokenAuth) {
    return <Redirect to="/login" />;
  }

  jwt.verify(tokenAuth, SIGNATURE, (err: VerifyErrors | null) => {
    if (err && err.message === 'jwt expired') {
      dispatch({ type: 'USER', user: null });
    }
  });

  const isUserMaxscallaAdmin = user?.nivel === 'M' && acesso === 'A';

  const isUserMasterLaserEletro = user?.id === 137 && acesso === 'A';

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!tokenAuth) {
          return <Redirect to="/login" />;
        }

        if (props.location.pathname === '/admin') {
          if (!isUserMaxscallaAdmin && !isUserMasterLaserEletro) {
            return props.history.goBack();
          }
        }

        const url = props.location.pathname;
        const queryParams = props.location.search;

        if (acesso !== 'R') {
          return <Component {...props} />;
        }

        if (url === '/' && !recursosRepresentantes?.acessoHome) {
          return <Redirect to="/gerar-pix" />;
        }

        if (url === '/conta-virtual' && !recursosRepresentantes?.acessoContaTransitoria) {
          return <Redirect to="/gerar-pix" />;
        }

        if (url === '/pix-gerados' && !recursosRepresentantes?.acessoPixGerados) {
          return <Redirect to="/gerar-pix" />;
        }

        if (url === '/planos') {
          return <Redirect to="/gerar-pix" />;
        }

        if (url === '/planos') {
          return <Redirect to="/gerar-pix" />;
        }

        if (url === '/usuarios' && (queryParams !== 'meu-perfil' && queryParams !== 'alterar-senha')) {
          return <Redirect to="/gerar-pix" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
