/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import { exibirErrorCatch } from '.';

export const setImagemBandeira = (brand: string): string => {
  const bandeira = brand.toLowerCase();
  const baseUrl = 'https://api.plom.com.br/img/mobile/bandeira_original_cartoes/';
  switch (bandeira) {
    case 'visa':
      return `${baseUrl}/visa.png`;
    case 'master' || 'mastercard':
      return `${baseUrl}/master.png`;
    case 'maestro':
      return `${baseUrl}/maestro.png`;
    case 'alelo':
      return `${baseUrl}/alelo.png`;
    case 'american' || 'amex':
      return `${baseUrl}/american.png`;
    case 'banricompras':
      return `${baseUrl}/banricompras.png`;
    case 'cooper':
      return `${baseUrl}/cooper.png`;
    case 'diners' || 'dinersclub':
      return `${baseUrl}/diners.png`;
    case 'electron' || 'visaelectron':
      return `${baseUrl}/electron.png`;
    case 'elo':
      return `${baseUrl}/elo.png`;
    case 'goodcard':
      return `${baseUrl}/goodcard.png`;
    case 'greencard':
      return `${baseUrl}/greencard.png`;
    case 'hiper' || 'hipercard':
      return `${baseUrl}/hipercard.png`;
    case 'pagseguro':
      return `${baseUrl}/pagseguro.png`;
    case 'refeisul':
      return `${baseUrl}/refeisul.png`;
    case 'sodexo':
      return `${baseUrl}/sodexo.png`;
    case 'ticket':
      return `${baseUrl}/ticket.png`;
    case 'valecard':
      return `${baseUrl}/valecard.png`;
    case 'verdecard':
      return `${baseUrl}/verdecard.png`;
    case 'verocard':
      return `${baseUrl}/verocard.png`;
    case 'vr':
      return `${baseUrl}/vr.png`;
    default:
      return `${baseUrl}/card-online.png`;
  }
};

export const logoCartaoCredito = async (numero: string): Promise<string> => {
  try {
    const Iugu = (window as any).Iugu;

    const brand = await Iugu.utils.getBrandByCreditCardNumber(numero);

    const urlImage = setImagemBandeira(brand);

    return urlImage;
  } catch (error) {
    exibirErrorCatch(error);
    return '';
  }
};
