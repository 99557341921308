/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import { StateColors } from '../@types';

type Placement = 'top' | 'bottom' | 'left' | 'right' | 'auto';

export type ConteudoPopover = {
    titulo?: string,
    texto?: string,
    manual?: string,
    posicao?: Placement,
    timeout?: number,
    colorButton: StateColors
};

const defaultOptions = {
  titulo: 'Informar pagamento',
  manual: 'Manual',
  verificar: 'Verificar',
  posicao: 'auto',
  timeout: 10000,
};

type Valores = {
  titulo: string;
  texto?: string | undefined;
  manual: string;
  posicao: string;
  timeout: number;
  verificar: string;
  colorButton: string;
};

const template = (valores: Valores) => `<div class="m-popover popover fade popover-pagamento" role="tooltip">
        <div class="arrow"></div>
        <div style="display: flex;justify-content: space-between;">
            <h3 class="popover-header"></h3>
            <button
                type="button"
                id="popover-close"
                class="btn btn-outline-metal m-btn--icon m-btn--icon-only m-btn--pill"
                style="margin: 6px 6px 0 0px;width: 22px;height: 22px;"
            >
                <i class="fa fa-close"></i>
            </button>
        </div>
        ${valores.texto ? '<div class="popover-body"></div>' : ''}
        <div style="padding: 12px 15px;">
            <a id="popover-pagamento" class="btn btn-sm btn-${valores.colorButton}" tabindex="0" style="margin-left: 5px;">
                ${valores.manual}
            </a>
        </div>
    </div>`.replace(/>\s+</g, '><');

const popoverConfirmacao = (
  container: Element,
  opcoes: ConteudoPopover = {} as ConteudoPopover,
): Promise<{ action: string }> => new Promise((resolve) => {
  const valores = { ...defaultOptions, ...opcoes };
  ($('.popover') as any).popover('hide');
  const $container: any = $(container);
  const parent = container.parentElement;
  if (!parent) {
    return;
  }
  const timeout = setTimeout(() => {
    $container.popover('hide');
  }, valores.timeout);

  $container
    .popover({
      title: valores.titulo,
      content: valores.texto,
      html: true,
      placement: valores.posicao,
      template: template(valores),
      trigger: 'manual',
    })
    .popover('show');

  const success = document.getElementById('popover-pagamento');
  const close = document.getElementById('popover-close');

  if (success) {
    success.onclick = () => {
      clearTimeout(timeout);
      $container.popover('hide');
      resolve({ action: 'manual' });
    };
  }

  if (close) {
    close.onclick = () => {
      clearTimeout(timeout);
      $container.popover('hide');
    };
  }
});

export default popoverConfirmacao;
