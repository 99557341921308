interface TraducaoStatus {
  color: string;
  name: string;
}

const traducaoStatus = (status: number): TraducaoStatus => {
  switch (status) {
    case 1:
      return {
        color: 'warning',
        name: 'Aguardando',
      };
    case 2:
      return {
        color: 'metal',
        name: 'Em análise',
      };
    case 3:
      return {
        color: 'success',
        name: 'Pago',
      };
    case 4:
      return {
        color: 'danger',
        name: 'Devolvido',
      };
    case 5:
      return {
        color: 'danger',
        name: 'Cancelado',
      };
    case 6:
      return {
        color: 'metal',
        name: 'Expirado',
      };
    default:
      return {
        color: 'accent',
        name: '',
      };
  }
};

export default traducaoStatus;
