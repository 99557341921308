import { CSSProperties, ReactElement } from 'react';

type Colors = 'warning' | 'brand' | 'info' | 'danger' | 'plom' | 'primary' | 'accent';

interface Props {
  color?: Colors;
  style?: CSSProperties;
  textAlert?: string;
  alert?: boolean;
}

const AlertEmptyGrid = ({
  color, style, textAlert, alert,
}: Props): ReactElement => (
  <div
    className={`animated fadeInLeft alert alert-${color ?? 'danger'}`}
    role="alert"
    style={{ fontWeight: 500, ...style }}
  >
    {alert && (
      <strong>
        Aviso!
      </strong>
    )}
    {' '}
    {textAlert ?? 'Não existem informações a serem exibidas!'}
  </div>
);

AlertEmptyGrid.defaultProps = {
  color: undefined,
  style: undefined,
  textAlert: undefined,
  alert: false,
};

export default AlertEmptyGrid;
