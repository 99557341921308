import { ReactElement } from 'react';
import { Operacao } from '../@types';
import { traducaoStatus } from '../utils';

interface Params {
  operacao: Operacao;
}

const BadgeStatus = ({ operacao }: Params): ReactElement => {
  const tipo = traducaoStatus(operacao.etapasId);

  return (
    <div>
      <span className={`m-badge m-badge--wide m-badge--${tipo.color}`}>
        {tipo.name}
        {' '}
      </span>
    </div>
  );
};

export default BadgeStatus;
