import { ReactElement } from 'react';
import { StateColors } from '../../@types';

interface Props {
  color: StateColors;
  conteudo: string;
}

const Badge = ({ color, conteudo }: Props): ReactElement => (
  <div>
    <span className={`m-badge m-badge--wide m-badge--${color} m--font-bolder`}>
      {conteudo}
      {' '}
    </span>
  </div>
);

export default Badge;
