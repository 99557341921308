import { ReactElement } from 'react';

interface Props {
  children?: ReactElement,
  xl: number,
  className?: string,
}

const Col = ({ children, xl, className }: Props): ReactElement => (
  <div className={`${className} col-xl-${xl}`}>
    {children}
  </div>
);

Col.defaultProps = {
  className: undefined,
  children: undefined,
};

export default Col;
