/* eslint-disable @typescript-eslint/no-explicit-any */
import { getElementoPorId } from '.';

/* eslint-disable no-unused-vars */
type Loading = {
  show: (id?: string, message?: string) => void,
  hide: (id?: string) => void,
};

const glo: any = global; // eslint-disable-line @typescript-eslint/no-explicit-any

const hide = (id = 'body'): void => {
  let elemento: any = id;

  if (id !== 'body') {
    elemento = getElementoPorId(id);

    if (!elemento) {
      return;
    }
  }

  glo.mApp.unblock(elemento);
};

const show = (id = 'body', message = 'Aguarde'): void => {
  let elemento: any = id;

  if (id !== 'body') {
    elemento = getElementoPorId(id);

    if (!elemento) {
      return;
    }
  }

  glo.mApp.block(elemento, {
    overlayColor: '#000000',
    type: 'loader',
    state: 'success',
    message,
  });
};

const loading: Loading = {
  hide,
  show,
};

export default loading;
