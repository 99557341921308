/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  FC, InputHTMLAttributes, ReactElement, useState,
} from 'react';
import { Input } from '..';

type InputProps = InputHTMLAttributes<HTMLInputElement>

const InputSenha: FC<InputProps> = ({ ...rest }): ReactElement => {
  const [verSenha, setVerSenha] = useState<boolean>(false);

  const handleVerSenha = () => {
    if (rest.disabled) return;

    setVerSenha(!verSenha);
  };

  return (
    <div className="m-input-icon m-input-icon--right">
      <input type="password" name={rest.name || 'senha'} style={{ display: 'none' }} />
      {rest.name ? (
        <Input
          type={verSenha ? 'text' : 'password'}
          className="form-control m-input"
          required
          {...rest}
        />
      ) : (
        <input
          type={verSenha ? 'text' : 'password'}
          className="form-control m-input"
          required
          {...rest}
        />
      )}
      <span className="m-input-icon__icon m-input-icon__icon--right">
        <span
          title="Clique aqui para mostrar/ocultar a senha"
          className="add-on input-group-addon"
          style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}
          onClick={handleVerSenha}
        >
          <i
            style={{ position: 'absolute', top: 20, right: 10 }}
            className={`fa-regular ${verSenha ? 'fa-eye-slash' : 'fa-eye'}`}
          />
        </span>
      </span>
    </div>
  );
};

export default InputSenha;
