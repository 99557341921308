/* eslint-disable jsx-a11y/role-supports-aria-props */
import { ReactElement, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Anchor } from '..';
import useUser from '../../hooks/useUser';
import useRecursosRepresentantes from '../../hooks/useRecursosRepresentantes';
import { autoloadFiles, getTheme } from '../../utils';

const AsideMenu = (): ReactElement => {
  const { pathname, search } = useLocation();
  const { user, acesso } = useUser();
  const { recursosRepresentantes } = useRecursosRepresentantes();

  const isFuncionario = acesso === 'R';

  const isMasterLaserEletro = user?.id === 137 && acesso === 'A';

  const isUserMaxscallaAdmin = user?.nivel === 'M' && acesso === 'A';

  const isSpeedPix = getTheme() === 'speedpix';

  const exibirOpcoesContaTransitoria = () => {
    if (!isFuncionario && user?.exibirOpcoesContaTransitoria === 1) {
      return true;
    }

    if (recursosRepresentantes?.acessoContaTransitoria
      && user?.exibirOpcoesContaTransitoria === 1) {
      return true;
    }
    return false;
  };

  const esconderLogoNoAside = useCallback(() => {
    const btnAside = document.getElementById('m_aside_left_minimize_toggle');
    const logo = document.getElementById('logo-max');

    if (!btnAside || !logo) {
      return;
    }

    btnAside.onclick = () => {
      if (btnAside.classList.value.includes('m-primary__toggler--active')) {
        logo.style.display = 'block';
      } else {
        logo.style.display = 'none';
      }
    };
  }, []);

  useEffect(() => {
    esconderLogoNoAside();
  }, [esconderLogoNoAside]);

  useEffect(() => {
    autoloadFiles();
  }, []);

  return (
    <div
      id="m_aside_left"
      className="m-grid__item m-aside-left m-aside-left--skin-dark flex flex-direction-column justify-content-space-between aside-paguemax-color"
    >
      <div
        id="m_ver_menu"
        className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
        data-menu-vertical="true"
        data-menu-scrollable="false"
        data-menu-dropdown-timeout="500"
      >
        <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
          <li className={`m-menu__item item_menu-speedpix ${pathname === '/usuario' ? 'item_menu-active-speedpix' : ''}`} />
          <div id="sidebar-primeira-divisao" />
          {!isFuncionario || recursosRepresentantes?.acessoHome ? (
            <>
              <li className={`m-menu__item item_menu-speedpix ${pathname === '/' ? 'item_menu-active-speedpix' : ''}`}>
                <Link to="/" className="m-menu__link pathname">
                  <i
                    className="m-menu__link-icon fa-light fa-house-user"
                    title="Home"
                    style={{ color: '#fff' }}
                  />
                  <span className="m-menu__link-text fw-500"> Home </span>
                </Link>
              </li>
              <div className="border-menu" />
            </>
          ) : null}
          <li className={`m-menu__item item_menu-speedpix ${pathname === '/gerar-pix' ? 'item_menu-active-speedpix' : ''}`}>
            <Link to="/gerar-pix" className="m-menu__link">
              <i
                className="m-menu__link-icon fa-light fa-qrcode"
                title="Gerar pix"
                style={{ color: '#fff' }}
              />
              <span className="m-menu__link-text fw-500"> Gerar pix </span>
            </Link>
          </li>
          {!isFuncionario || recursosRepresentantes?.acessoPixGerados ? (
            <>
              <div className="border-menu" />
              <li className={`m-menu__item item_menu-speedpix ${pathname === '/pix-gerados' ? 'item_menu-active-speedpix' : ''}`}>
                <Link to="/pix-gerados" className="m-menu__link">
                  <i
                    className="m-menu__link-icon fa-light fa-file-check"
                    title="Pix gerados"
                    style={{ color: '#fff' }}
                  />
                  <span className="m-menu__link-text fw-500"> Pix gerados </span>
                </Link>
              </li>
            </>
          ) : null}
          {!isFuncionario ? (
            <li className={`m-menu__item item_menu-speedpix ${pathname === '/planos' ? 'item_menu-active-speedpix' : ''}`}>
              <div className="border-menu" />
              <Link to="/planos" className="m-menu__link">
                <i
                  className="m-menu__link-icon fa-light fa-file-pen"
                  title="Planos"
                  style={{ color: '#fff' }}
                />
                <span className="m-menu__link-text fw-500">Planos</span>
              </Link>
            </li>
          ) : null}
          {exibirOpcoesContaTransitoria() ? (
            <li className={`m-menu__item item_menu-speedpix ${pathname === '/conta-virtual' ? 'item_menu-active-speedpix' : ''}`}>
              <div className="border-menu" />
              <Link to="/conta-virtual" className="m-menu__link">
                <i
                  className="m-menu__link-icon fa-light fa-display"
                  title="Conta transitória"
                  style={{ color: '#fff', fontSize: 16 }}
                />
                <span className="m-menu__link-text fw-500">Conta transitória</span>
              </Link>
            </li>
          ) : null}
          {!isFuncionario ? (
            <>
              <div className="border-menu" />
              <li className={`m-menu__item item_menu-speedpix ${pathname === '/usuarios' ? 'item_menu-active-speedpix' : ''}`}>
                <Link to="/usuarios" className="m-menu__link pathname">
                  <i
                    className="m-menu__link-icon fa-light fa-user-group"
                    title="Usuários"
                    style={{ color: '#fff', fontSize: 17 }}
                  />
                  <span className="m-menu__link-text fw-500">Usuários</span>
                </Link>
              </li>
            </>
          ) : null}
          <li
            id="dropdown-configuracoes"
            className="m-menu__item m-menu__item--submenu"
            aria-haspopup="true"
            data-menu-submenu-toggle="hover"
          >
            <div className="border-menu" />
            <Anchor className="m-menu__link m-menu__toggle item_menu-speedpix">
              <i className="m-menu__link-icon fa-light fa-gear" style={{ color: '#fff' }} />
              <span className="m-menu__link-text fw-500">Configurações</span>
              <i className="m-menu__ver-arrow la la-angle-right" style={{ color: '#fff' }} />
            </Anchor>
            <div className="m-menu__submenu" style={{ display: 'none' }}>
              <span className="m-menu__arrow" />
              <ul className="m-menu__subnav">
                <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=meu-perfil' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                  <Link to="/usuario?pagina=meu-perfil" className="m-menu__link">
                    <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                      <span style={{ backgroundColor: '#fff' }} />
                    </i>
                    <span className="m-menu__link-text">Meu perfil</span>
                  </Link>
                </li>
                <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=alterar-senha' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                  <Link to="/usuario?pagina=alterar-senha" className="m-menu__link">
                    <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                      <span style={{ backgroundColor: '#fff' }} />
                    </i>
                    <span className="m-menu__link-text">Alterar senha</span>
                  </Link>
                </li>
                {!isFuncionario ? (
                  <>
                    <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=configuracao-pix' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                      <Link to="/usuario?pagina=configuracao-pix" className="m-menu__link">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span style={{ backgroundColor: '#fff' }} />
                        </i>
                        <span className="m-menu__link-text">Chaves Pix</span>
                      </Link>
                    </li>
                    <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=whatsapp' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                      <Link to="/usuario?pagina=whatsapp" className="m-menu__link">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span style={{ backgroundColor: '#fff' }} />
                        </i>
                        <span className="m-menu__link-text">Whatsapp</span>
                      </Link>
                    </li>
                    {isSpeedPix && (
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=cartoes-credito' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/usuario?pagina=cartoes-credito" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Cartões de crédito</span>
                        </Link>
                      </li>
                    )}
                  </>
                ) : null}
              </ul>
            </div>
          </li>
          {!isFuncionario ? (
            <li
              id="dropdown-documentacoes"
              className="m-menu__item m-menu__item--submenu"
              aria-haspopup="true"
              data-menu-submenu-toggle="hover"
            >
              <div className="border-menu" />
              <Anchor className="m-menu__link m-menu__toggle item_menu-speedpix">
                <i className="m-menu__link-icon fa-light fa-circle-question" style={{ color: '#fff' }} />
                <span className="m-menu__link-text fw-500">Documentações</span>
                <i className="m-menu__ver-arrow la la-angle-right" style={{ color: '#fff' }} />
              </Anchor>
              <div className="m-menu__submenu" style={{ display: 'none' }}>
                <span className="m-menu__arrow" />
                <ul className="m-menu__subnav">
                  {isSpeedPix && (
                    <>
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=banco-nao-integrado' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/documentacao?pagina=banco-nao-integrado" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Banco não integrado</span>
                        </Link>
                      </li>
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=bb' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/documentacao?pagina=bb" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Banco do Brasil</span>
                        </Link>
                      </li>
                    </>
                  )}
                  {exibirOpcoesContaTransitoria() ? (
                    <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=conta-virtual' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                      <Link to="/documentacao?pagina=conta-virtual" className="m-menu__link">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span style={{ backgroundColor: '#fff' }} />
                        </i>
                        <span className="m-menu__link-text">Conta transitória</span>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </div>
            </li>
          ) : null}
          {(isUserMaxscallaAdmin || isMasterLaserEletro) && (
            <li
              id="dropdown-admin"
              className="m-menu__item m-menu__item--submenu"
              aria-haspopup="true"
              data-menu-submenu-toggle="hover"
            >
              <div className="border-menu" />
              <Anchor className="m-menu__link m-menu__toggle item_menu-speedpix">
                <i
                  className="m-menu__link-icon fa-light fa-chart-line-up"
                  title="Administrativo"
                  style={{ color: '#fff' }}
                />
                <span className="m-menu__link-text fw-500">Administrativo</span>
                <i className="m-menu__ver-arrow la la-angle-right" style={{ color: '#fff' }} />
              </Anchor>
              <div className="m-menu__submenu" style={{ display: 'none' }}>
                <span className="m-menu__arrow" />
                <ul className="m-menu__subnav">
                  {isUserMaxscallaAdmin && (
                    <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=usuarios' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                      <Link to="/admin?pagina=usuarios" className="m-menu__link">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span style={{ backgroundColor: '#fff' }} />
                        </i>
                        <span className="m-menu__link-text">Clientes</span>
                      </Link>
                    </li>
                  )}
                  {isUserMaxscallaAdmin && (
                    <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=movimento-do-dia' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                      <Link to="/admin?pagina=movimento-do-dia" className="m-menu__link">
                        <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                          <span style={{ backgroundColor: '#fff' }} />
                        </i>
                        <span className="m-menu__link-text">Movimento do dia</span>
                      </Link>
                    </li>
                  )}
                  <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=operacoes' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                    <Link to="/admin?pagina=operacoes" className="m-menu__link">
                      <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                        <span style={{ backgroundColor: '#fff' }} />
                      </i>
                      <span className="m-menu__link-text">Operações</span>
                    </Link>
                  </li>
                  {isUserMaxscallaAdmin && (
                    <>
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=contas-flagship' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/admin?pagina=contas-flagship" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Contas transitórias</span>
                        </Link>
                      </li>
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=contatos-demo' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/admin?pagina=contatos-demo" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Acessos demo</span>
                        </Link>
                      </li>
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=extrato' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/admin?pagina=extrato" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Extratos</span>
                        </Link>
                      </li>
                      <li className={`m-menu__item item_menu-speedpix ${search === '?pagina=cadatros-pendentes' ? 'item_menu-active-speedpix' : ''}`} aria-haspopup="true">
                        <Link to="/admin?pagina=cadatros-pendentes" className="m-menu__link">
                          <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                            <span style={{ backgroundColor: '#fff' }} />
                          </i>
                          <span className="m-menu__link-text">Cadastros pendentes</span>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </li>
          )}
          <li className="m-menu__item item_menu-speedpix">
            <div className="border-menu" />
            <Link to="/logout" className="m-menu__link">
              <i
                className="m-menu__link-icon fa-light fa-arrow-right-from-bracket"
                title="Sair"
                style={{ color: '#fff', fontSize: 18 }}
              />
              <span className="m-menu__link-text fw-500">Sair</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex justify-content-center">
        <Anchor href="https://maxscalla.com.br/" target="_blank">
          <img
            src="/theme/src/media/app/img/logos/logo-max-branco196x47.png"
            alt="Logo MaxScalla"
            style={{ width: 150, marginBottom: 50 }}
            id="logo-max"
          />
        </Anchor>
      </div>
    </div>
  );
};

export default AsideMenu;
