/* eslint-disable no-unused-vars */
import { ReactElement } from 'react';
import { TypeFilter } from '../@types';
import { Button } from '.';

type Props = {
    pageAtual: number,
    setFilter: (value: TypeFilter) => void;
    totalPages: number,
}

const Paginacao = ({
  pageAtual,
  setFilter,
  totalPages,
}: Props): ReactElement => {
  const nextPage = () => {
    if (pageAtual < totalPages) {
      setFilter({ page: pageAtual + 1 });
    }
  };

  const backPage = () => {
    if (pageAtual > 1) {
      setFilter({ page: pageAtual - 1 });
    }
  };

  const setPagina = (page: number) => setFilter({ page });

  return (
    <div style={{ margin: 10 }} className="m-datatable__pager m-datatable--paging-loaded clearfix">
      <ul className="m-datatable__pager-nav">
        <li>
          <Button
            type="button"
            title="Previous"
            className="btn m-datatable__pager-link"
            disabled={pageAtual === 1}
            onClick={() => setPagina(1)}
          >
            <i className="la la-angle-double-left" />
          </Button>
        </li>
        <li>
          <Button
            type="button"
            title="Previous"
            className="btn m-datatable__pager-link"
            onClick={backPage}
            disabled={pageAtual === 1}
          >
            <i className="la la-angle-left" />
          </Button>
        </li>
        {pageAtual > 1 && (
          <li>
            <Button
              type="button"
              className="btn m-datatable__pager-link m-datatable__pager-link-number"
              onClick={() => setPagina(pageAtual - 1)}
            >
              {pageAtual - 1}
            </Button>
          </li>
        )}
        <li>
          <Button
            type="button"
            className="btn m-datatable__pager-link m-datatable__pager-link-number m-datatable__pager-link--active btn-filter-page"
          >
            {pageAtual}
          </Button>
        </li>
        {pageAtual < totalPages && (
          <li>
            <Button
              type="button"
              className="btn m-datatable__pager-link m-datatable__pager-link-number"
              onClick={() => setPagina(pageAtual + 1)}
            >
              {pageAtual + 1}
            </Button>
          </li>
        )}
        <li>
          <Button
            type="button"
            title="Next"
            className="btn m-datatable__pager-link"
            onClick={nextPage}
            disabled={pageAtual === totalPages}
          >
            <i className="la la-angle-right" />
          </Button>
        </li>
        <li>
          <Button
            type="button"
            title="More pages"
            className="btn m-datatable__pager-link"
            onClick={() => setPagina(totalPages)}
            disabled={pageAtual === totalPages}
          >
            <i className="la la-angle-double-right" />
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default Paginacao;
