/* eslint-disable no-unused-vars */
import {
  useState, createContext, ReactNode, useEffect, useCallback, ReactElement,
} from 'react';
import { ConfigPix } from '../@types';
import useUser from '../hooks/useUser';
import { api, exibirErrorCatch, loading } from '../utils';

interface Props {
  children: ReactNode;
}

export interface StateConfigPixAtiva {
  configPix: ConfigPix | null,
  setConfigPix: (x: ConfigPix | null) => void,
}

export const ConfigPixContext = createContext({} as StateConfigPixAtiva);

const ConfigPixAtivaContext = ({ children }: Props): ReactElement => {
  const { user } = useUser();
  const [configPix, setConfigPix] = useState<ConfigPix | null>(null);

  const getConfigPix = useCallback(async () => {
    try {
      if (!user) return;

      const { data } = await api.getConfigPix();

      if (data) {
        setConfigPix(data);
      }
    } catch (error) {
      exibirErrorCatch(error);
    } finally {
      loading.hide();
    }
  }, [user]);

  useEffect(() => {
    getConfigPix();
  }, [getConfigPix]);

  return (
    <ConfigPixContext.Provider value={{ configPix, setConfigPix }}>
      {children}
    </ConfigPixContext.Provider>
  );
};

export default ConfigPixAtivaContext;
