import axios, { AxiosRequestHeaders, AxiosRequestConfig } from 'axios';
import { API } from './url-api';
import store from '../store';

const header = (tokenAuth: string | null) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  Authorization: `Bearer ${tokenAuth}`,
});

const getHeaders = (): AxiosRequestHeaders => {
  const { tokenAuth } = store.getState();

  return header(tokenAuth);
};

const xhr = axios.create({
  baseURL: API,
  headers: getHeaders(),
});

export const setTokenAfterLogin = (tokenAuth: string): void => {
  xhr.interceptors.request.use(
    async (config: AxiosRequestConfig) => ({
      ...config,
      headers: header(tokenAuth),
    }),
    (error) => Promise.reject(error),
  );

  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

xhr.interceptors.response.use((response) => response, (error) => Promise.reject(error));

export default xhr;
