import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useAssinaturaAtiva from '../../../hooks/useAssinaturaAtiva';
import { getTheme } from '../../../utils';

const PlanoAtivo = (): ReactElement => {
  const { assinatura } = useAssinaturaAtiva();
  return (
    <li
      className="d-flex flex-row align-items-center pointer"
      data-toggle="tooltip"
      data-original-title="Plano ativo"
      style={{ position: 'relative' }}
    >
      <Link to="/planos" className="m-d-flex flex-row align-items-center anchor-text-decoration-none">
        <div className="d-flex flex-row text-body" style={{ alignItems: 'baseline' }}>
          <i className="fa fa-solid fa-calendar-pen" style={{ fontSize: '1.5rem', color: getTheme() === 'speedpix' ? 'rgb(0 189 174)' : '#e67d1d' }} />
          {assinatura && assinatura.plano && (
            <span className="badge-header">
              {assinatura?.plano.nome}
            </span>
          )}
          <span className="option-text">
            Planos disponíveis
          </span>
        </div>
      </Link>
    </li>
  );
};

export default PlanoAtivo;
