/* eslint-disable no-unused-vars */
import { ReactElement } from 'react';
import SpanRequired from '../layout/SpanRequired';
import changeKeys from '../../utils/change-chave-pix';
import { TipoChavePix } from '../../@types';

interface Props {
  tipoPix: string;
  chavePix: string;
  typeError: string;
  set: {
    chavePix: (x: string) => void;
    typeError: (x: TipoChavePix) => void;
  };
  origem: 'Configuração' | 'Cadastro';
  title?: string;
  classNameSpan?: string;
  classNameInput?: string;
  disabled?: boolean;
}

const InputChavePix = ({
  tipoPix,
  chavePix,
  typeError,
  set,
  origem,
  title,
  classNameSpan,
  classNameInput,
  disabled,
}: Props): ReactElement => (
  <div className={`${origem === 'Configuração' ? 'form-group m-form__group row justify-content-center' : 'm--margin-top-0'}`}>
    {origem === 'Configuração' && (
    <span className={classNameSpan}>
      {title}
      <SpanRequired />
    </span>
    )}
    {tipoPix === 'cpf' && (
    <div className={`${origem === 'Configuração' ? classNameInput : undefined}`}>
      <input
        type="cpf"
        className="form-control m-input"
        name="chave-pix"
        id="chave-pix"
        placeholder="Digite apenas os dígitos do cpf"
        value={chavePix}
        maxLength={14}
        onChange={(e) => changeKeys.onChangeCpf(e, set.chavePix, set.typeError)}
        disabled={disabled}
        required
      />
      {typeError === 'cpf' && (
      <span className="m-form__help m--font-danger">
        cpf inválido.
      </span>
      )}
    </div>
    )}
    {tipoPix === 'cnpj' && (
    <div className={`${origem === 'Configuração' ? classNameInput : undefined}`}>
      <input
        type="text"
        className="form-control"
        id="chave-pix"
        name="chave-pix"
        placeholder="Digite apenas os dígitos do CNPJ"
        value={chavePix}
        maxLength={18}
        onChange={(e) => changeKeys.onChangeCnpj(e, set.chavePix, set.typeError)}
        disabled={disabled}
        required
      />
      {typeError === 'cnpj' && (
      <span className="m-form__help m--font-danger">
        Cnpj inválido.
      </span>
      )}
    </div>
    )}
    {tipoPix === 'email' && (
    <div className={`${origem === 'Configuração' ? classNameInput : undefined}`}>
      <input
        className="form-control"
        type="email"
        id="chave-pix"
        name="chave-pix"
        placeholder="E-mail"
        value={chavePix}
        onChange={(e) => changeKeys.onChangeEmail(e, set.chavePix, set.typeError)}
        disabled={disabled}
        required
      />
      {typeError === 'email' && (
      <span className="m-form__help m--font-danger">
        E-mail inválido.
      </span>
      )}
    </div>
    )}
    {tipoPix === 'celular' && (
    <div className={`${origem === 'Configuração' ? classNameInput : undefined}`}>
      <input
        className="form-control"
        id="chave-pix"
        name="chave-pix"
        value={chavePix}
        placeholder="Digite seu número com o DDD"
        onChange={(e) => changeKeys.onChangeTelefone(e, set.chavePix, set.typeError)}
        disabled={disabled}
        required
      />
      {typeError === 'telefone' && (
      <span className="m-form__help m--font-danger">
        Número de celular inválido.
      </span>
      )}
    </div>
    )}
    {tipoPix === 'aleatoria' && (
    <div className={`${origem === 'Configuração' ? classNameInput : undefined}`}>
      <input
        className="form-control"
        type="text"
        id="chave-pix"
        name="chave-pix"
        placeholder="Chave aleatória"
        value={chavePix}
        maxLength={36}
        onChange={(e) => changeKeys.onChangeAleatoria(e, set.chavePix, set.typeError)}
        disabled={disabled}
        required
      />
      {typeError === 'aleatorio' && (
      <span className="m-form__help m--font-danger">
        Chave aleatória incompleta.
      </span>
      )}
    </div>
    )}
  </div>
);

InputChavePix.defaultProps = {
  title: 'Chave Pix:',
  classNameInput: 'col-lg-6 col-md-9 col-sm-12',
  classNameSpan: 'col-form-label col-lg-3 col-sm-12',
  disabled: false,
};

export default InputChavePix;
