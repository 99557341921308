/* eslint-disable react/jsx-props-no-spreading */
import { HTMLAttributes, ReactElement } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactElement;
}

const PortletHead = ({ children, ...rest }: Props): ReactElement => (
  <div className="m-portlet__head" {...rest}>
    {children}
  </div>
);

export default PortletHead;
