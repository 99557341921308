/* eslint-disable max-len */
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import getCorFundoFotoUsuario from '../../../utils/get-cor-fundo-foto-usuario';
import Anchor from '../../layout/Anchor';
import { getTheme } from '../../../utils';

const BgImg = getTheme() === 'speedpix' ? 'fundo-verde.png' : 'fundo-roxo.png';

const DropdownPerfil = (): ReactElement => {
  const { user, representante } = useUser();

  return (
    <li
      title="Meu usuário"
      className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
      style={{ padding: 0 }}
      data-dropdown-toggle="click"
    >
      <Anchor
        className="btn m-nav__link m-dropdown__toggle flex align-items-center m-btn--air p-0"
      >
        <div className="m-widget4__img m-widget4__img--logo m-card-user m-card-user--sm">
          <div className="m-card-user__pic" style={{ paddingRight: 0 }}>
            <div
              className={`m-card-user__no-photo m--bg-fill-${getCorFundoFotoUsuario(String(representante ? representante.nome : user?.nomeContato))}`}
              style={{ height: 35, width: 35 }}
            >
              <span>
                {representante ? representante.nome.substr(0, 1) : user?.nomeContato.substr(0, 1)}
              </span>
            </div>
          </div>
        </div>
      </Anchor>
      <div className="m-dropdown__wrapper">
        <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust color-speedpix-dark" />
        <div className="m-dropdown__inner">
          <div
            className="m-dropdown__header m--align-center"
            style={{
              background: `url(/theme/src/media/app/img/misc/${BgImg})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="m-card-user m-card-user--skin-dark">
              <div className="m-card-user__pic">
                <div
                  className={`m-card-user__no-photo m--bg-fill-${getCorFundoFotoUsuario(String(representante ? representante.nome : user?.nomeContato))}`}
                  style={{ border: '2px solid white' }}
                >
                  <span style={{ fontSize: 30 }}>
                    {representante ? representante.nome.substr(0, 1) : user?.nomeContato.substr(0, 1)}
                  </span>
                </div>
              </div>
              <div className="m-card-user__details">
                <span
                  className="m-card-user__name text-capitalize"
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  {representante ? representante.nome : user?.nomeContato}
                </span>
                <span
                  className="m-card-user__name text-uppercase mt-1 ellipsis"
                  style={{ fontSize: 16, fontWeight: 700, maxWidth: '16ch' }}
                >
                  {user?.nome}
                </span>
              </div>
            </div>
          </div>
          <div className="m-dropdown__body">
            <div className="m-dropdown__content">
              <ul className="m-nav m-nav--skin-light">
                <li className="m-nav__item">
                  <Link
                    to="/usuario?pagina=meu-perfil"
                    className="m-nav__link"
                  >
                    <i className="m-nav__link-icon fa flaticon-settings-1" />
                    <span className="m-nav__link-title">
                      <span className="m-nav__link-wrap">
                        <span className="m-nav__link-text">
                          Configurações
                        </span>
                      </span>
                    </span>
                  </Link>
                </li>
                <li className="m-nav__separator m-nav__separator--fit" />
                <li className="m-nav__item">
                  <Link
                    to="/logout"
                    className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-primary m-btn--bolder"
                    style={{ color: '#03877c' }}
                  >
                    Sair
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default DropdownPerfil;
