import { ReactElement } from 'react';

const DividerHeader = (): ReactElement => (
  <div
    style={{
      border: '1px solid #ebedf2',
      marginInline: 15,
      height: '60%',
    }}
  />
);

export default DividerHeader;
