/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';
import { Representante } from '../@types';

interface Props {
  setValue: (x: number | string) => void;
  representantes?: Representante[]
}

const SelectRepresentantes = ({ setValue, representantes }: Props): ReactElement => (
  <select
    id="select-representantes"
    name="select-representantes"
    className="form-control m-input"
    onChange={(e: ChangeEvent<HTMLSelectElement>) => (
      setValue(e.target.value)
    )}
  >
    <option value="" disabled selected>Selecione um usuário...</option>
    {representantes && representantes.map((representante: Representante) => (
      <option value={representante.id}>{representante.nome}</option>
    ))}
    <option value="%">Todos</option>
  </select>
);

export default SelectRepresentantes;

SelectRepresentantes.defaultProps = {
  representantes: [],
};
