import { Assinatura, StateColors } from '../@types';

interface Status {
  status: string;
  color: StateColors;
}

const handleBadgeStatusAssinatura = (assinatura: Assinatura | null): Status => {
  if (!assinatura) {
    return { status: 'Inativa', color: 'metal' };
  }

  if (assinatura.ativo && assinatura.pendente) {
    return { status: 'Pagamento pendente', color: 'warning' };
  }

  if (assinatura.ativo && assinatura.suspended) {
    return { status: 'Suspensa', color: 'danger' };
  }

  if (assinatura.plano.identifier === 'basic') {
    return { status: assinatura.plano.nome, color: 'info' };
  }

  if (assinatura.plano.identifier === 'full') {
    return { status: assinatura.plano.nome, color: 'success' };
  }

  if (assinatura.plano.identifier === 'taxa_0_100') {
    return { status: assinatura.plano.nome, color: 'brand' };
  }

  return { status: assinatura.plano.nome, color: 'metal' };
};

export default handleBadgeStatusAssinatura;
