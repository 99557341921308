/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';

interface Props {
  setValue: (x: number | string) => void;
  value: string | number;
}

const SelectStatusAssinatura = ({ setValue, value }: Props): ReactElement => (
  <select
    id="select-status-assinatura"
    name="select-status-assinatura"
    className="form-control m-input"
    value={value}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => (
      setValue(e.target.value)
    )}
  >
    <option disabled value="">Selecione um status</option>
    <option value="assinatura_inativa">Inativa</option>
    <option value="assinatura_ativa">Ativa</option>
    <option value="assinatura_pendente">Pagamento pendente</option>
    <option value="assinatura_suspensa">Suspensa</option>
    <option value="%">Todos</option>
  </select>
);

export default SelectStatusAssinatura;
