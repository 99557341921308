/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';
import apiIugu from './api-iugu';

// eslint-disable-next-line
export const catchErrorGerarToken = (error: any) => {
  if (error.first_name || error.last_name) {
    return 'Digite o nome completo.';
  }

  if (error.number) {
    return `O número de cartão ${error.number}`;
  }

  if (error.month || error.year) {
    return 'Data de validade incorreta';
  }

  if (error.verification_value) {
    return `O CVV está inválido ${error.verification_value}`;
  }

  return '';
};

interface GerarTokenCartao {
  numeroCartao: string;
  cvv: string;
  nomeTitular: string;
  expiracao: string;
}

// eslint-disable-next-line
export const gerarTokenIugu = async ({
  numeroCartao,
  cvv,
  nomeTitular,
  expiracao,
}: GerarTokenCartao) => {
  const primeiroNome = nomeTitular.split(' ')[0];
  const ultimoNome = nomeTitular.split(' ').slice(-1)[0];
  const mesCartao = expiracao.split('/')[0];
  const anoCartao = expiracao.split('/').slice(-1)[0];

  const { data } = await api.getDadosIugu();

  const params = {
    account_id: data.iuguId,
    method: 'credit_card',
    test: false,
    data: {
      number: Number(numeroCartao.replace(/\s+/g, '')),
      verification_value: cvv,
      first_name: primeiroNome,
      last_name: ultimoNome,
      month: mesCartao,
      year: anoCartao,
    },
  };

  return apiIugu.gerarTokenFormaPagamento(params);
};
