/* eslint-disable import/prefer-default-export */
import { getTheme } from './get-theme';

export const handleIndex = () => {
  const isSpeedPix = getTheme() === 'speedpix';
  const firstIcon = document.getElementById('first-icon');
  const title = document.getElementById('first-title');

  if (title) {
    title.innerText = isSpeedPix ? 'SpeedPix' : 'PixZero';
  }

  if (firstIcon) {
    firstIcon.setAttribute(
      'href',
      `/${isSpeedPix ? 'favicon.ico' : 'faviconpz.ico'}`,
    );
  }
};
