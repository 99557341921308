/* eslint-disable no-unused-vars */
import { ReactElement, ChangeEvent } from 'react';
import { getInputById } from '../utils';

interface Props {
  setValue: (x: number | string) => void;
}

const bancos = [
  {
    id: 1,
    name: 'Banco do Brasil',
    value: '001',
  },
  {
    id: 2,
    name: 'Bradesco',
    value: '239',
  },
  {
    id: 3,
    name: 'Itaú',
    value: '341',
  },
  {
    id: 4,
    name: 'Conta transitória flagship',
    value: '999',
  },
  {
    id: 5,
    name: 'Todos',
    value: '%',
  },
];

const DataList = ({ setValue }: Props): ReactElement => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const item = bancos.find((i) => i.name === e.target.value);

    if (!item) {
      const inputElement = getInputById('filter-banco');
      setValue(inputElement.value);
      return;
    }

    setValue(item.value);
  };

  return (
    <>
      <input
        id="filter-banco"
        className="form-control"
        list="select-banco"
        placeholder="Pesquise um banco"
        onChange={onChange}
      />
      <datalist id="select-banco">
        <>
          {bancos.map((s) => (
            <option key={s.id} value={s.name}>{s.name}</option>
          ))}
        </>
      </datalist>
    </>
  );
};

export default DataList;
