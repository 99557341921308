/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactElement } from 'react';
import { InfosOperacoes } from '../@types';
import { Portlet } from '.';
import Card from './Card';

interface Props {
  infosOperacoes: InfosOperacoes;
}

const CardsInformacoesOperacoes = ({ infosOperacoes }: Props): ReactElement => {
  const porcentagemGerados = infosOperacoes.numeroOperacoesGeradas ? '100%' : '0%';
  const porcentagemAguardando = infosOperacoes.numeroOperacoesGeradas
    ? `${Number((infosOperacoes.numeroOperacoesAguardando / infosOperacoes.numeroOperacoesGeradas) * 100).toFixed(2)}%`
    : '0%';
  const porcentagemVencidas = infosOperacoes.numeroOperacoesGeradas
    ? `${Number((infosOperacoes.numeroOperacoesVencidas / infosOperacoes.numeroOperacoesGeradas) * 100).toFixed(2)}%`
    : '0%';
  const porcentagemCancelado = infosOperacoes.numeroOperacoesCanceladas
    ? `${Number((infosOperacoes.numeroOperacoesCanceladas / infosOperacoes.numeroOperacoesGeradas) * 100).toFixed(2)}%`
    : '0%';
  const porcentagemPagas = infosOperacoes.numeroOperacoesGeradas
    ? `${Number((infosOperacoes.numeroOperacoesPagas / infosOperacoes.numeroOperacoesGeradas) * 100).toFixed(2)}%`
    : '0%';

  return (
    <Portlet animated="fadeInRight">
      <div className="row m-row--col-separator-md cards-grid">
        <Card
          numeroOperacoes={infosOperacoes.numeroOperacoesGeradas}
          valor={infosOperacoes.valorTotalGerado}
          porcentagem={porcentagemGerados}
          color="primary"
          width="20%"
          title="Geradas"
        />
        <Card
          numeroOperacoes={infosOperacoes.numeroOperacoesAguardando}
          valor={infosOperacoes.valorTotalAguardando}
          porcentagem={porcentagemAguardando}
          color="warning"
          width="20%"
          title="Aguardando"
        />
        <Card
          numeroOperacoes={infosOperacoes.numeroOperacoesVencidas}
          valor={infosOperacoes.valorTotalVencido}
          porcentagem={porcentagemVencidas}
          color="metal"
          width="20%"
          title="Expiradas"
        />
        <Card
          numeroOperacoes={infosOperacoes.numeroOperacoesCanceladas}
          valor={infosOperacoes.valorTotalCancelado}
          porcentagem={porcentagemCancelado}
          color="danger"
          width="20%"
          title="Canceladas"
        />
        <Card
          numeroOperacoes={infosOperacoes.numeroOperacoesPagas}
          valor={infosOperacoes.valorTotalPago}
          porcentagem={porcentagemPagas}
          color="success"
          width="20%"
          title="Pagas"
        />
      </div>
    </Portlet>
  );
};

export default CardsInformacoesOperacoes;
