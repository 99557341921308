import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

const GridBody = ({ children }: Props): ReactElement => (
  <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
    {children}
  </div>
);

export default GridBody;
